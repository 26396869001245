<script>
    import { smscreen } from "./stores";
    import { get } from "lodash-es";
    import Policy from "./Policy.svelte";
    import PolicyUsage from "./PolicyUsage.svelte";
    import Permits from "./Permits.svelte";
    import NoPolicies from "./NoPolicies.svelte";
    import page from "page";

    export let policies = null;
    export let policy = null;
    export let permits = null;
    export let valid = null;

    // function navigate(e) {

    //     if(!policy || e.target.value !== policy.subject) 

    // }

</script>
{#if !!policies && !!policies.length}
{#if policy && $smscreen}
<nav>
    <select value={policy.subject} on:change={e => (page(`${window.location.pathname}?policy=${e.target.value}`))}>
       {#each policies as item} 
        <option value="{item.subject}">{item.title}</option>
       {/each}
    </select>
</nav>
{/if}
<ul class="policies" data-count="{get(policies, "length", "")}">
    {#each policies as item}
        {#if !policy || !$smscreen || policy.subject === item.subject}
        <li>
            <header>
                <!-- <a href="/properties/{item.scope}/amenities/{item.subject}"> -->
                    <Policy policy={item} />
                <!-- </a> -->
                <PolicyUsage valid={valid} policy={item} capacity={true} used={true} />
            </header>
            <Permits permits={permits} valid={valid} policy={item} />
        </li>
        {/if}
    {/each}
</ul>
{:else if !!policies && policies.length === 0}
<NoPolicies />
{/if}