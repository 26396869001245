<script>
    import { 
        startOfDay, 
        endOfDay, 
        startOfWeek,
        subWeeks,
        endOfWeek
    } from "date-fns";
    import { map } from "lodash-es";
    import DailyUsageChart from "./DailyUsageChart.svelte";
    import Odometer from "./Odometer.svelte";
    import { 
        getCountForDateRange,
        getDailyUsageChartData
    } from "../../util/metrics.js";
    import { propertyTimezone, valid, minuteNow } from "../../stores";
    import { toInterval, stringToInterval } from "../../util/datetime"
    import { areIntervalsOverlapping } from "date-fns";

    export let policy;
    export let data;

    $: now = $valid || $minuteNow;
    $: today = { start: startOfDay(now), end: endOfDay(now)}
    $: thisWeek = { start: startOfWeek(now), end: now }
    $: lastWeek = { start: startOfWeek(subWeeks(now, 1)), end: endOfWeek(subWeeks(now, 1)) }
    
    $: todayCount = data?.values && getCountForDateRange(data.values, today);
    $: thisWeekCount = data?.values && getCountForDateRange(data.values, thisWeek);
    $: previousWeekCount = data?.values && getCountForDateRange(data.values, lastWeek);
    $: weekDifference = thisWeekCount && previousWeekCount && Math.round((thisWeekCount / previousWeekCount) * 100);
    $: dailyUsageData = data?.values && getDailyUsageChartData(data.values, thisWeek, lastWeek, $propertyTimezone);

    $: intervalNow = toInterval(now);
    $: statistics = policy && policy.statistics && policy.statistics.intervals;
    $: usageNow = intervalNow && statistics && map(statistics, (value, interval) => {
        let [ usage ] = value.split("=");
        usage = usage.split("/").map(i => parseInt(i));
        return {
            utc: stringToInterval(interval),
            used: usage[0],
            capacity: usage[1],
            closed: usage[1] == 0,
        }
    }).find(item => areIntervalsOverlapping(intervalNow, item.utc, {
        inclusive: intervalNow.start == item.utc.start, // inclusive on start (allows for a zero-interval at beginning)
    }));
</script>

<tr class="metrics-table-row" on:click>
    <td class="title">{policy.title}</td>
    <td class="active">
        {#if !usageNow}
            <p>{"—"}</p>
        {:else if usageNow.capacity === 4294967295}
            <p class="capacity-message">unlimited</p>
        {:else if usageNow.closed}
            <p class="capacity-message">closed</p>
        {:else if !!usageNow.capacity}
            <Odometer value={usageNow.used} max={usageNow.capacity} />
        {/if}
    </td>
    <td class="today">{todayCount || "—"}</td>
    <td>
        <div class="week">
            <span class="current">{thisWeekCount || "—"}</span>
            {#if weekDifference}
                <span class="difference" 
                    class:neutral={weekDifference === 100 || !isFinite(weekDifference)} 
                    class:positive={weekDifference > 100} 
                    class:negative={weekDifference < 100}>
                        {#if weekDifference > 100}
                            {`${weekDifference}%`}
                        {:else if weekDifference < 100}
                            {`${weekDifference}%`}
                        {/if}
                </span>
            {/if}
        </div>
    </td>
    <td>
        <DailyUsageChart data={dailyUsageData} />
    </td>
</tr>


