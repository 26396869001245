<script>
  import { format, utcToZonedTime } from "date-fns-tz";
  import { toMilliseconds, normalize } from "duration-fns";
  import { fetchUpdatePolicyMetersConcurrent, fetchUpdatePolicy } from "./api";
  import {
    get,
    range,
    filter,
    map,
    size,
    each,
    clone,
    sortedUniq,
    zipObjectDeep,
  } from "lodash-es";
  import { metersFromPolicy } from "./util/policy";
  import { help } from "./util/help";

  export let policy = null;
  export let issue = true;

  let newFiles = {};

  let unattach = {};

  let submittable = false;
  let submitting = false;

  let editingPolicy = null;

  //$: editingPolicy = policy;
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = clone(policy);

  let selectedMinDurationISO = null;

  $: minDurationISO =
    selectedMinDurationISO ||
    get(editingPolicy, "duration.min") ||
    get(editingPolicy, "duration") ||
    "PT1H";
  $: maxDurationISO =
    get(editingPolicy, "duration.max") ||
    get(editingPolicy, "duration") ||
    "PT1H";
  //$: issue = !!get(editingPolicy, "issue.enabled");
  //$: unitType = get(editingPolicy, "tenant.format", "unit");
  $: minDurationMS = toMilliseconds(minDurationISO);
  $: maxDurationMS = toMilliseconds(maxDurationISO);
  $: maxDurationValues = calculateMaxDuration(minDurationMS, maxDurationMS);

  $: concurrentMeters = (metersFromPolicy(editingPolicy) || []).filter(
    ({ format, concurrent, group, principal, principals }) =>
      format === "concurrent" &&
      concurrent > 0 &&
      !group &&
      !principal &&
      !Object.values(principals ?? {}).length
  );
  // see if we can find any custom set metering
  $: customConcurrent =
    concurrentMeters.length > 1 ||
    !!concurrentMeters.find(
      (item) => !!item.subject || Object.values(item.subjects).length > 1
    );
  $: maxConcurrent = Math.max(
    ...concurrentMeters.map(({ concurrent }) => concurrent),
    0
  );

  $: simplified = get(editingPolicy, "tenant.simplified", false);

  $: name = !issue || !!get(editingPolicy, "name.request");

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editingPolicy || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);
    each(newFiles, (item) => {
      if (!!item.attach) formData.append("file", item.file);
    });

    await Promise.all([
      fetchUpdatePolicy(editingPolicy, formData),
      fetchUpdatePolicyMetersConcurrent(editingPolicy, formData),
    ]);

    each(newFiles, (item) => {
      URL.revokeObjectURL(item.url);
    });

    // clear the state
    newFiles = {};
    unattach = {};
    submitting = submittable = false;
    editingPolicy = null;
  }

  //     {
  // "generated": "2020-05-09T19:45:42.480Z",
  // "type": "meter",
  // "format": "concurrent",
  // "id": "ew7twv2vph2efepvq5k0104c6c",
  // "subject": "37pbzbrmmx3ws5a5czd24z2n2c",
  // "uuid": "770fae6c-5bb4-44e7-badb-b96600808c33",
  // "rank": 5,
  // "display": "5 at a time",
  // "concurrent": 5,
  // "valid": {
  // "interval": "2020-05-02T17:12:37.287Z/",
  // "days": {
  // "1": "Monday",
  // "2": "Tuesday",
  // "3": "Wednesday",
  // "4": "Thursday",
  // "5": "Friday",
  // "6": "Saturday",
  // "7": "Sunday"
  // },
  // "cumulative": "P7D"
  // }
  // }

  const times = [
    0.25 * 60 * 60 * 1000,
    0.5 * 60 * 60 * 1000,
    0.75 * 60 * 60 * 1000,
    1.0 * 60 * 60 * 1000,
    1.25 * 60 * 60 * 1000,
    1.5 * 60 * 60 * 1000,
    1.75 * 60 * 60 * 1000,
    2.0 * 60 * 60 * 1000,
    2.5 * 60 * 60 * 1000,
    3.0 * 60 * 60 * 1000,
    3.5 * 60 * 60 * 1000,
    4.0 * 60 * 60 * 1000,
    4.5 * 60 * 60 * 1000,
    5.0 * 60 * 60 * 1000,
    6.0 * 60 * 60 * 1000,
    7.0 * 60 * 60 * 1000,
    8.0 * 60 * 60 * 1000,
    10.0 * 60 * 60 * 1000,
    12.0 * 60 * 60 * 1000,
    14.0 * 60 * 60 * 1000,
    16.0 * 60 * 60 * 1000,
    18.0 * 60 * 60 * 1000,
    20.0 * 60 * 60 * 1000,
    22.0 * 60 * 60 * 1000,
    24.0 * 60 * 60 * 1000,
    36.0 * 60 * 60 * 1000,
    48.0 * 60 * 60 * 1000,
  ];

  function getTimeOptions(selected, max) {
    if (times.includes(selected)) return times.filter((i) => !max || i < max);

    return sortedUniq(times.concat([selected]).sort((a, b) => a - b));
  }

  function calculateMaxDuration(min, max) {
    //const max = 24 * 60 * 60 * 1000;
    const items = [];
    //let i = min;

    for (let i = 1; i <= 8; i++) {
      items.push(i * min);
      if (!!max) items.push(i * max);
    }

    // // first 8 steps then on the hour
    // for(i = min; i < max; i += min) {
    //     if(i <= 8 * min) items.push(i);
    //     else if(i % (60 * 60 * 1000) === 0) items.push(i);
    // }

    //console.log(sortedUniq(items.filter(i => i <= (48 * 60 * 60 * 1000)).sort()));

    return sortedUniq(
      items.filter((i) => i <= 48 * 60 * 60 * 1000).sort((a, b) => a - b)
    );
  }

  function toISOString(ms) {
    const parsed = normalize(ms);
    const hours = parsed.days * 24 + parsed.hours;
    return (
      "PT" +
      (hours > 0 ? hours + "H" : "") +
      (parsed.minutes > 0 ? parsed.minutes + "M" : "")
    );
  }

  function formatTime(ms) {
    const parsed = normalize(ms);
    const hours = parsed.days * 24 + parsed.hours;
    return `${hours}:${parsed.minutes.toString().padStart(2, "0")}`;
  }

  function addFile(e) {
    if (!e.target.files || e.target.files.length <= 0) return;
    each(e.target.files, (file) => {
      // only take pdf
      if (file.type !== "application/pdf") return;
      const key = `file${size(newFiles) + 1}`;
      newFiles[key] = {
        id: key,
        file,
        url: URL.createObjectURL(file),
        attach: true,
      };
    });
    //console.log(newFiles);
    newFiles = newFiles;
    e.target.value = "";
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>General Rules & Limits</h1>
  </header>
  {#if editingPolicy}
    {#if customConcurrent}
      <ul>
        {#each concurrentMeters as item}
          <li>
            <h1>
              {item.display}
              {#if !item.valid.interval.endsWith("/")}
                - {item.valid.interval
                  .split("/")
                  .map((str) =>
                    format(
                      utcToZonedTime(str, policy.timezone),
                      " EE MMM d yyyy h:mm a"
                    )
                  )
                  .join(" - ")}
              {/if}{[
                item.match,
                item.group && `per ${item.group.replace("tenant", "unit")}`,
              ]
                .filter((i) => !!i)
                .join(" ")}
            </h1>
            {#each Object.entries(item.principals || {}) as [id, item]}<data
                value={id}>{item.display}</data
              >{/each}{#if Object.values(item.subjects || {}).length > 1}{#each Object.entries(item.subjects || {}) as [id, title]}<data
                  value={id}>{title}</data
                >{/each}{/if}
          </li>
        {/each}
      </ul>
      <p>
        These are custom limits, please <a
          on:click={help}
          href="mailto:help@amenityboss.com?subject={policy.title} Limits"
          >contact us</a
        > for help.
      </p>
    {:else}
      <fieldset>
        {#if issue && !name}
          <input type="hidden" name="permit.meter.concurrent" value="1" />
        {/if}
        {#if issue && name}
          <input type="hidden" name="permit.notes" value="" />
        {/if}
        <ul>
          {#if issue}
            <li>
              <label for="policy-field-name">Sharing</label>
              <select
                id="policy-field-name"
                name="permit.contact.name"
                value={name || ""}
                on:blur={(e) => (name = e.target.value === "true")}
                on:change={(e) => (name = e.target.value === "true")}
              >
                <option value="true">anyone with pass</option>
                <!-- <option value="false">Optional</option> -->
                <option value=""
                  >one {get(editingPolicy, "tenant.format")} at a time</option
                >
              </select>
            </li>
            <!-- <li>
                    <label>Name on pass</label>
                    <select name="permit.contact.name" value="{name+""}" disabled>
                        <option value="true">required</option>
                        <option value="false">not applicable</option>
                    </select>
                </li> -->
          {/if}
          {#if name}
            <li>
              <label for="policy-concurrent">Capacity</label>
              <select
                id="policy-concurrent"
                name="permit.meter.concurrent"
                value={maxConcurrent + "" || "false"}
                readonly={!name}
              >
                {#if name}<option value="false">no limit</option>{/if}
                <option value={1 + ""}
                  >only 1 {issue ? "pass" : "person"}</option
                >
                {#if name}
                  {#each range(2, 100 + 1) as i}
                    <option value={i + ""}
                      >max {i}
                      {issue
                        ? i == 1
                          ? "pass"
                          : "passes"
                        : i == 1
                          ? "person"
                          : "people"}</option
                    >
                  {/each}
                {/if}
              </select>
            </li>
          {/if}

          {#if issue}
            <li>
              <label for="policy-tenant-simplified">Limit</label>
              <select
                id="policy-tenant-simplified"
                name="permit.tenant.simplified"
                disabled={!name}
                value={(simplified && name) + ""}
                on:blur={(e) => (simplified = e.target.value === "true")}
                on:change={(e) => (simplified = e.target.value === "true")}
              >
                <option value="true">by group time</option>
                <option value="false">by total time</option>
              </select>
            </li>
          {/if}
        </ul>
      </fieldset>
      {#if issue}
        {#if !simplified || !name}
          <input
            type="hidden"
            name="permit.tenant.meter.concurrent"
            value="false"
          />
          <input type="hidden" name="permit.tenant.simplified" value="false" />
        {/if}
      {/if}
    {/if}
    <fieldset>
      <p>Additional rules:</p>
      <ul>
        <li>
          <!-- <label for="policy-field-rules">Message</label> -->
          <textarea
            id="policy-field-rules"
            name="rules"
            value={get(editingPolicy, "rules.custom", "")}
            placeholder="optional"
          ></textarea>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>Documents:</p>
      <ul>
        {#each map(get(editingPolicy, "documents.items", [])) as document}
          <li>
            <input
              type="checkbox"
              name="unattach"
              checked={!!unattach[document.id]}
              value={document.id}
              on:change={(e) => (unattach[e.target.value] = e.target.checked)}
            />
            <a
              href={document.url.replace(
                "https://upload.parkingboss.com/files/",
                "https://amenitypass.imgix.net/"
              )}
              target="_blank"
              type={document.format}
            >
              <data class="document file" value={document.id}>
                <figure>
                  <img
                    alt="preview"
                    src="{document.url.replace(
                      'https://upload.parkingboss.com/files/',
                      'https://amenitypass.imgix.net/'
                    )}?auto=compress,format&w=200&dpr=2"
                  />
                  <figcaption>
                    <h1>{document.title || document.name}</h1>
                  </figcaption>
                </figure>
              </data>
            </a>
          </li>
        {/each}
        {#each map(newFiles) as newFile}
          <li>
            <input
              type="checkbox"
              name="unattach"
              checked={!newFile.attach}
              value={newFile.id}
              on:change={(e) => (newFile.attach = !e.target.checked)}
            />
            <a href={newFile.url} target="_blank" type={newFile.file.type}>
              <data class="document file" value={newFile.id}>
                <figure>
                  <img alt="preview" />
                  <figcaption>
                    <h1>{newFile.file.name}</h1>
                  </figcaption>
                </figure>
              </data>
            </a>
          </li>
        {/each}
        <li>
          <input
            id="policy-file-new"
            type="file"
            name="file"
            accept="application/pdf"
            on:change={addFile}
            multiple
          />
          <label for="policy-file-new">Attach document</label>
        </li>
      </ul>
    </fieldset>
    {#if issue}
      <p>
        Please note: changing pass limits will only apply to new passes and any
        existing passes will remain active.
      </p>
    {/if}
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  {/if}
</form>
