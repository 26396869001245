<script>
    import * as d3 from "d3";   
    import ChartAxis from "./ChartAxis.svelte";
    import { BarChartType } from "./BarChartType.js";
    import { days } from "../../util/metrics";
    import ChartLine from "./ChartLine.svelte";
    import { utcToZoned } from "../../util/datetime";
    
    export let margin = {
        top: 20,
        bottom: 20,
        left: 32,
        right: 0,
    };
    export let data;
    export let type;
    export let timezone;

    const barWidth = 4;

    let width = 0;
    let height = 0;

    $: insetSize = {width: width - (margin.left + margin.right), height: height - (margin.top + margin.bottom)}
    $: xScale = d3.scaleBand()
                    .range([0, insetSize.width])
                    .domain(xDomain(type))
                    .round(true);
    $: yMax = data?.values && d3.max(Object.values(data.values)) || 1;
    $: yScale = d3.scaleLinear()
                .range([insetSize.height, 0])
                .domain([0, yMax]);
  
    const xDomain = type => {
        switch (type) {
            case BarChartType.week:
                return days;
            case BarChartType.month:
                if (!data?.values) {
                    return [];
                }
                const dates = Object.keys(data.values).reduce((acc, key) => {
                    const [start, _] = key.split("/");
                    const date = utcToZoned(start, timezone);
                    acc.push(date);
                    return acc;
                }, []);
                return dates;
            case BarChartType.year:
                return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        }
    }

    const tickFormat = (value, index) => {
        switch (type) {
            case BarChartType.week:
                return value.toLowerCase()[0];
            case BarChartType.month:
                if (index === 0 || index % 7 === 0) {
                    return value.getDate();
                } 
                return;
            case BarChartType.year:
                return value.toLocaleString("default", {month: "long"})[0].toLowerCase();
        }
    };

    const xValue = (value) => {
        const [start, _] = value.split("/");
        const date = utcToZoned(start, timezone);
        let x;
        switch (type) {
            case BarChartType.week: 
                const day = date.toLocaleString("default", {weekday: "long"});
                x = xScale(day);
                break;
            case BarChartType.month: 
                x = xScale(date);
                break;
            case BarChartType.year:
                const year = date.toLocaleString("default", {month: "long"});
                x = xScale(year);
                break;
        }
        
        x += (xScale.bandwidth() / 2) - (barWidth / 2);
        return x;
    };
</script>


<div class="chart-bar" on:click bind:clientWidth={width} bind:clientHeight={height}>
    {#if width && height}
        <svg {width} {height}>
            <ChartLine text={yMax} x1={margin.left} x2={width} {margin} />
            <ChartAxis 
                scale={xScale} 
                position="bottom" 
                {height} 
                {margin}
                {tickFormat} 
            />
            {#if data?.values}
                <g transform={`translate(${margin.left}, ${margin.top})`}>
                    {#each Object.entries(data.values) as [key, value]}
                        <rect 
                            x={xValue(key)}
                            y={yScale(value)}
                            width={barWidth}
                            height={value > 0 ? insetSize.height - yScale(value) : 0}
                            rx={2} />
                    {/each}
                </g>
            {/if}
        </svg>
    {/if}
</div>
