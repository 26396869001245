<script>
    export let available = false;
    export let capacity = false;
    export let used = false;
    export let title = null;
</script>
<p>
<!-- {#if !!title}
    <dfn>{title}</dfn>
{/if} -->
{#if used !== false}
<data value="{used}" class="used"></data>
{/if}
{#if available !== false}
    <data value="{available}" class="available"></data>
{/if}
{#if capacity !== false}
<data value="{capacity}" class="capacity">{title || ""}</data>
{/if}
</p>