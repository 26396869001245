<script>

    import { toMilliseconds, normalize } from "duration-fns";   
    import { fetchUpdatePolicyMeters } from "../api";
    import { get, range, filter, sortedUniq } from "lodash-es";
    import { metersFromPolicy } from "../util/policy";
    import { help } from "../util/help";

    export let policy;

    //export let principal = null;
    export let issue = true;
    export let principals = false;

    let principal = null;

    $:editing = policy;

    let submittable = false;
    let submitting = false;
    let customize = false;

    //$: simplified = (get(editing, "tenant.simplified", false));
    //$: unitType = get(editing, "tenant.title", "Unit");
    $: name = !issue || !!get(editing, "name.request");

    $: meters = (metersFromPolicy(editing) || []);

    // these are the specific meters

    function isMeterPrincipal(group, meter, principal) {
        if(!principal && !meter.principal && meter.group == group) return true;
        if(!principal || !meter.principal) return false;
        return meter.principal == (principal.id || principal);
    }

    $: hasMultiSubjectMeters = meters.reduce((result, meter) => {
        return result || Object.values(meter.subjects || {}).length > 1;
    }, false);

    $: principalMeters = meters.reduce((map, meter) => {
        if(!meter.principal) return map;
        (map[meter.principal] = (map[meter.principal] || [])).push(meter);
        return map;
    }, {});

    $: anyPrincipalMeters = !!Object.keys(principalMeters).length;

    $: hasPrincipalMeters = principal && principalMeters[principal.id || principal];

    $: tenantConcurrent = Math.max(...meters.filter(meter => "concurrent" === meter.format && meter.concurrent > 0 && isMeterPrincipal("tenant", meter, principal)
        //({ format, concurrent, group }) => format === "concurrent" && concurrent > 0 && "tenant" === group
    ).map(({ concurrent}) => concurrent), 0);

    $: tenantDayMS = toMilliseconds(
        meters.filter(meter => "time" === meter.format && "P1D" === meter.period && isMeterPrincipal("tenant", meter, principal)
        ).map(({time}) => time)[0] || "PT0S");

    $: tenantWeekMS = toMilliseconds(
        meters.filter(meter => "time" === meter.format && "P1W" === meter.period && isMeterPrincipal("tenant", meter, principal)
        ).map(({time}) => time)[0] || "PT0S");

    $: tenantMonthMS = toMilliseconds(
        meters.filter(meter => "time" === meter.format && "P1M" === meter.period && isMeterPrincipal("tenant", meter, principal)
            )
        .map(({time}) => time)[0] || "PT0S");

    $: console.log("concurrent=", tenantConcurrent, " day=", tenantDayMS, " week=", tenantWeekMS, " month=", tenantMonthMS);

    const times = [
        0.25 * 60 * 60 * 1000,
        0.50 * 60 * 60 * 1000,
        0.75 * 60 * 60 * 1000,
        1.00 * 60 * 60 * 1000,
        1.25 * 60 * 60 * 1000,
        1.50 * 60 * 60 * 1000,
        1.75 * 60 * 60 * 1000,
        2.00 * 60 * 60 * 1000,
        2.50 * 60 * 60 * 1000,
        3.00 * 60 * 60 * 1000,
        3.50 * 60 * 60 * 1000,
        4.00 * 60 * 60 * 1000,
        4.50 * 60 * 60 * 1000,
        5.00 * 60 * 60 * 1000,
        6.00 * 60 * 60 * 1000,
        7.00 * 60 * 60 * 1000,
        8.00 * 60 * 60 * 1000,
        10.00 * 60 * 60 * 1000,
        12.00 * 60 * 60 * 1000,
        14.00 * 60 * 60 * 1000,
        16.00 * 60 * 60 * 1000,
        18.00 * 60 * 60 * 1000,
        20.00 * 60 * 60 * 1000,
        22.00 * 60 * 60 * 1000,
        24.00 * 60 * 60 * 1000,
        32.00 * 60 * 60 * 1000,
        36.00 * 60 * 60 * 1000,
        48.00 * 60 * 60 * 1000,
    ];

    function getTimeOptions(selected, max) {

        if(times.includes(selected)) return times.filter(i => !max || i < max);

        return sortedUniq(times.concat([ selected ]).sort((a, b) => a - b));

    }

    function toISOString(ms) {
        const parsed = normalize(ms);
        const hours = (parsed.days * 24) + parsed.hours;
        return "PT" + (hours > 0 ? hours + "H" : "") + ((parsed.minutes > 0 ? parsed.minutes + "M" : ""));
    }

    function formatTime(ms) {
        const parsed = normalize(ms);
        const hours = (parsed.days * 24) + parsed.hours;
        return `${hours}:${parsed.minutes.toString().padStart(2, "0")}`;
    }

    function change(e) {
        submittable = e.target.form && e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!editing || submitting || !submittable) return;

        submitting = true;

        const form = e.target;

        const formData = new FormData(form);

        // save
        await Promise.all([
            //fetchUpdatePolicy(editingPolicy, formData),
            fetchUpdatePolicyMeters(editing, formData)
        ]);

        // clear the state

        submitting = submittable = false;
        //editing = null;
        changePrincipal(principal);

        

    }

    function changePrincipal(value) {
        principal = value;
        customize = !!principalMeters[principal];
        submittable = false;
    }

    function onPrincipalChange(e) {
        changePrincipal(e.target.value);
    }

</script>
<form on:change={change} on:input={change} on:submit={submit}>
    <header>
        <h1>Per Unit Limits</h1>
    </header>
    
    {#if hasMultiSubjectMeters}
    <ul>
        {#each meters.filter(meter => meter.concurrent != 0 && (meter.group || Object.values(meter.principals || {}).length > 0)) as item}
        <li>
            <h1>
                {item.display}
                {#if !item.valid.interval.endsWith('/')}
                - {item.valid.interval.split('/').map(str => format(utcToZonedTime(str, policy.timezone), " EE MMM d yyyy h:mm a")).join(" - ")}
                {/if}{[ item.match, item.group && `per ${item.group.replace("tenant", "unit")}` ].filter(i => !!i).join(" ")}</h1>{#each Object.entries(item.principals || {}) as [ id, item ]}<data value="{id}">{item.display}</data>{/each}{#if Object.values(item.subjects || {}).length > 1}{#each Object.entries(item.subjects || {}) as [ id, title ]}<data value="{id}">{title}</data>{/each}{/if}
        </li>
        {/each}
</ul>
        <p>This amenity has a custom limits, please <a on:click={help} href="mailto:help@amenityboss.com?subject={policy.title} Limits">contact us</a> for help.</p>
    {:else}
    <fieldset>
        <ul>
            <li>
                <label for="policy-meter-principal">Unit</label>
                <select id="policy-meter-principal" name="permit.meter.tenant.id" value="{principal && (principal.id || principal)}" on:change={onPrincipalChange} on:blur={onPrincipalChange}>
                    <option value="">default limits</option>
                    {#if principals}
                    <optgroup label="Using default limits:">
                    {#each principals.filter(i => !principalMeters[i.id]) as { id, display }}
                    <option value="{id}">{display.toUpperCase()}</option>
                    {/each}
                    </optgroup>
                    {/if}
                    {#if anyPrincipalMeters}
                    <optgroup label="Using custom limits:">
                    {#each principals.filter(i => principalMeters[i.id]) as { id, display }}
                    <option value="{id}">{display.toUpperCase()}</option>
                    {/each}
                    </optgroup>
                    {/if}
                </select>
            </li>
            {#if principal}
            <li>
                <label for="policy-meter-override">Limits</label>
                <select id="policy-meter-override" bind:value={customize}>
                    <option value={false}>use default</option>
                    <option value={true}>custom</option>
                </select>
            </li>
            {/if}
        </ul>
    </fieldset>
    {#if true === customize || !principal}
    <fieldset>
        <!-- {#if principal}
        <p>Set at least one limit:</p>
        {/if} -->
        <ul>
            {#if name}
                <li>
                    <label for="policy-tenant-concurrent">Group size</label>
                    <select id="policy-tenant-concurrent" name="permit.tenant.meter.concurrent" value={tenantConcurrent || ""}>
                        <option value="false">no limit</option>
                        <option value={1}>only 1 pass</option>
                        {#each range(2, 10 + 1) as i}
                            <option value={i}>max {i} passes</option>
                        {/each}
                    </select>
                </li>
            
            {/if}
            <li>
                <label for="policy-tenant-day">Per day</label>
                <select id="policy-tenant-day" name="permit.tenant.meter.day" value={tenantDayMS > 0 ? toISOString(tenantDayMS): "false"}>
                    <option value="false">no limit</option>
                    {#each getTimeOptions(tenantDayMS) as i}
                        <option value="{toISOString(i)}">max {formatTime(i)}</option>
                    {/each}
                </select>
            </li>
            <li>
                <label for="policy-tenant-week">Per week</label>
                <select id="policy-tenant-week" name="permit.tenant.meter.week" value={tenantWeekMS > 0 ? toISOString(tenantWeekMS): "false"}>
                    <option value="false">no limit</option>
                    {#each getTimeOptions(tenantWeekMS) as i}
                        <option value="{toISOString(i)}">max {formatTime(i)}</option>
                    {/each}
                </select>
            </li><li>
                <label for="policy-tenant-month">Per month</label>
                <select id="policy-tenant-month" name="permit.tenant.meter.month" value={tenantMonthMS > 0 ? toISOString(tenantMonthMS): "false"}>
                    <option value="false">no limit</option>
                    {#each getTimeOptions(tenantMonthMS) as i}
                        <option value="{toISOString(i)}">max {formatTime(i)}</option>
                    {/each}
                </select>
            </li>
        </ul>
    </fieldset>
    {/if}
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save"}</button>
    </footer>
    {/if}
</form>