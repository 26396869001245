import { get } from "lodash-es";

//export const numericAsc = (a, b) => parseInt(a) < parseInt(b) ? -1 : parseInt(a) > parseInt(b) ? 1 : 0;

export const textAsc = !!self.Intl && !!self.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;

export const ASC = textAsc;

export const comparer = function(property, by=textAsc, reverse=false) {
    return (a, b) => by(typeof property == "function" ? property(a) : get(a, property, a), typeof property == "function" ? property(b) : get(b, property, b)) * (reverse ? -1 : 1);
}

export function sortBy(list, property, by=textAsc, reverse=false) {
    list.sort(comparer(property, by, reverse));
    return list;
}