<script>

    import { utcToZonedTime } from "date-fns-tz";
    import { addDays, format } from "date-fns";
    import { sortBy } from "../util/sort";


    export let data;
    export let property;

    $: items = processData(data, property);

    $: console.log("items=", items);

    function label(freq, interval, timezone) {
        if(freq == "P1D") return format(utcToZonedTime(interval.split('/')[0], timezone), "EEE MMM d");
        if(freq == "P1M") return format(utcToZonedTime(interval.split('/')[0], timezone), "MMM yyyy");
        if(freq == "P1W") return `${format(utcToZonedTime(interval.split('/')[0], timezone), "MMM d")}—${format(addDays(utcToZonedTime(interval.split('/')[1], timezone), -1), "MMM d")}`;
        return "since " + format(utcToZonedTime(interval.split('/')[0], timezone), "MMM d");
    }


    function processData(data, property) {

        if(!data) return {};

        return data.metrics.items.reduce((intervals, item) => {

            if(property && item.group != "property") return intervals;
            //if(item.payments != "total") return map;

            for(const [ interval, cents ] of Object.entries(item.values)) {
                if(interval.split('/')[0] < item.interval.split('/')[0]) continue; // don't have full data
                if(!intervals[interval]) intervals[interval] = {};
                intervals[interval].interval = interval;
                intervals[interval].datetimes = item.datetimes;
                intervals[interval][item.payments] = cents;
                intervals[interval].timezone = item.timezone;
                intervals[interval].label = label(item.datetimes, interval, item.timezone);
            }

            return intervals;

        }, {});

    }
    var currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0,
        //maximumFractionDigits: 0,
    }).format;
</script>
<figure class="payments metrics">
    {#each sortBy(Object.values(items).filter(i => i.datetimes == "P1M"), "interval", undefined, true) as data}
    <time datetime="{data.interval}">
        <dfn>{data.label}</dfn>
        <data class="net" value="{data.net}">{currency(data.net / 100) || ""}</data>
        <!-- <data class="gross" value="{data.total}">{currency(data.total / 100) || ""} total</data> -->
    </time> 
    {:else}
    <time datetime="">
        <dfn>Loading…</dfn>
    </time>
    <time datetime="">
        <dfn>Loading…</dfn>
    </time>
    <time datetime="">
        <dfn>Loading…</dfn>
    </time>        
    {/each}
</figure>