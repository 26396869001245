<script>
    import TableColumnHeader from "./TableColumnHeader.svelte";

    export let columns;
    // export let onSort;
    // export let sortBy;

    const onColumnClick = event => {
        // const index = parseInt(event.currentTarget.id)
        // const column = columns[index].column;
        // sortBy = { column, ascending: sortBy?.ascending ? !sor }
        // onSort($$props.id, sortBy);
    };

</script>

<style>
    table {
        border-radius: 4px;
        box-shadow: 1px 6px 22px rgba(33, 43, 54, 0.1);
        font-weight: 300;
        font-size: 32px;
        line-height: 37px;
        color: #3B3C41;
    }
    table :global(td) {
        padding: 0;
        border-bottom: none !important;
    }

    table :global(th) {
        position: static;
        top: unset;
        background-color: white;
    }

    table :global(tr) {
        background-color: white;
    }

    thead {
        height: 60px;
    }
    
</style>

<table>
    <thead>
        <tr>
            {#each columns as column, i}
                <TableColumnHeader id={i} title={column.title} />
            {/each}
        </tr>
    </thead>
    <tbody>
        <slot></slot>
    </tbody>
</table>