<script>
    import { uniqueId } from "lodash-es";

    export let units = [];
    export let selected = null;

    export const id = uniqueId('unit-select-');

    function onUnit(e) {

        selected = units.find(i => i.id === e.target.value || i.display == e.target.value);

        console.log(selected);

        if(!!selected) e.target.value = "";
    }

</script>

{#if selected}
    <label for="{id}">
        <data class="{selected.type} id {[ selected.format, selected.size ].filter(i => !!i).join(" ")}" value="{selected.id}">{selected.display}</data>
    </label>
{/if}
<input id="{id}" type="text" autocomplete="off" autocorrect="off" autocapitalize="characters" list="{id}-units" spellcheck="false" value="" maxlength="30" on:change={onUnit} on:input={onUnit} on:focus={e => selected = null}/>

<datalist id="{id}-units">
    {#each units as item}
        <option value="{item.display}">{item.display}</option>
    {/each}
</datalist>
<select tabindex="-1" on:change={onUnit}>
    <option value="">----</option>
    {#each units as item}
        <option value="{item.id}">{item.display}</option>
    {/each}
</select>
