<script>

    import { toMilliseconds, toMinutes } from "duration-fns";
    import { minuteNow } from "../stores";
    import { format, utcToZonedTime } from "date-fns-tz";
    import { query } from "../util/router";

    export let policy;
    export let valid;

    let minMS = 0;
    let maxMS = 0;

    $: if(policy) {
        minMS = toMilliseconds(policy.duration.min || policy.duration);
        maxMS = toMilliseconds(policy.duration.max || policy.duration);
    }

    $: options = buildAgenda(policy?.timezone, $minuteNow, policy?.statistics?.intervals, maxMS);

    $: console.log("options=", options);

    function buildAgenda(timezone, asOf, usage, maxTimeMS) {

        if(!usage) return [];

        let values = Object.entries(usage);

        const results = values.reduce((result, [ interval, value], i) => {

            console.log("item=", interval, value);

            // self
            const [ usage, title ] = value.split("=");
            const [ used, capacity ] = usage.split("/").map(i => parseInt(i));

            const [ min, max] = interval.split('/');
            const [ minUtc, maxUtc ] = interval.split('/').map(i => new Date(i));
            const [ minZoned, maxZoned ] = interval.split('/').map(i => utcToZonedTime(i, timezone));

            if(used >= capacity) return result; // no usage remaining for this slot

            result.push({
                interval,
                utc: {
                    start: minUtc,
                    end: maxUtc,
                },
                local: {
                    start: minZoned,
                    end: maxZoned,
                    timezone
                },
                duration:toMinutes(maxUtc.getTime() - minUtc.getTime()),
                used: used,
                capacity: capacity,
                available: capacity - used,

            });

            // build from further?

            for(const [ interval, value ] of values.slice(i + 1)) {

                const [ nextMin, nextMax ] = interval.split('/');
                const [ nextUsage, nextTitle ] = value.split("=");
                const [ nextUsed, nextCapacity ] = nextUsage.split("/").map(i => parseInt(i));

                const [ nextMinUtc, nextMaxUtc ] = interval.split('/').map(i => new Date(i));

                if(nextMaxUtc.getTime() - minUtc.getTime() > maxTimeMS) continue; // would go over max time

                if(nextUsed >= nextCapacity) continue; // no usage remaining for this slot


                const [ nextMinZoned, nextMaxZoned ] = interval.split('/').map(i => utcToZonedTime(i, timezone));

                const aggregateCapacity = Math.min(capacity, nextCapacity);
                const aggregateUsed = Math.max(used, nextUsed);

                result.push({
                    interval:`${min}/${nextMax}`,
                    utc: {
                        start: minUtc,
                        end: nextMaxUtc,
                    },
                    local: {
                        start: minZoned,
                        end: nextMaxZoned,
                        timezone
                    },
                    duration:toMinutes(nextMaxUtc.getTime() - minUtc.getTime()),
                    used: aggregateUsed,
                    capacity: aggregateCapacity,
                    available: aggregateCapacity - aggregateUsed,

                });


            }

            return result;

        }, []);

        return results;
        
        const times = reduce(usage, (result, value, interval) => {

            

            usage = usage.split("/").map(i => parseInt(i));
            // convert to client relative time
            const local = stringToDates(interval, deviceTimeAsIfLocal);
            const utc = stringToDates(interval);
            //.split("/").map(d => new Date(d.replace(/[+-][0-9]{1,2}:?[0-9]{2}$/, format(new Date(d), "xxx")))).map(d => format(d, "yyyy-MM-dd'T'HH:mm:ssxxx")).join("/");
            //const utcInterval = interval.split("/").map(d => new Date(d).toISOString()).join("/");
            //console.log("usage=", usage, interval, localInterval, usage);
            var prev = result[result.length - 1];


            const item = {
                interval: interval,
                title,
                local: local,
                used:usage[0],
                capacity:usage[1],
                available: (usage[1] - usage[0]),
                full: (usage[0] >= usage[1]),
                closed: (usage[1] === 0),
                block: (usage[0] >= usage[1] || usage[1] === 0),
                past:(utc[1] < asOf),
                start:utc[0],
                end:utc[1],
            };
            //result[utc.map(d => d.toISOString()).join()] = item;
            if(utc[1] >= asOf) result.push(item);

            return result;
        }, []);

        return orderBy(times.map(t => {

            const currentlySelected = isSelected(selected, t);

            //console.log('status=', currentlySelected, selected, t);

            return Object.assign(t, {
                selected:currentlySelected,
                selectable:currentlySelected || (!!selectable && !t.block && maySelectTime(asOf, selected, t, times, multiple, maxTimeMS)),
            });
        }), [ "start"]);
    }

</script>
{#each options as item}
<button on:click={e => query("valid", item.interval)} type="button" value={item.interval}>{format(item.local.start, "EEE h:mm a")} - {format(item.local.end, "EEE h:mm a")} ({item.duration} min) = {item.used} of {item.capacity}, {item.available} available</button>
{/each}