<script>

    export let id;
    export let value;
    export let policies;
    export let empty = "all";

</script>

<!-- {#if policies} -->
<select id={id} value="{value||""}" on:change={e => (value = e.target.value)}>
    <option value="">{empty}</option>
{#each (policies||[]) as item}
<option value="{item.policy}">{item.title}</option>
{/each}
</select>
<!-- {/if} -->