<script>

    import { fetchCreateUser } from "./api";

    export let property = null;

    let submittable = false;
    let submitting = false;

    function change(e) {
        submittable = e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!property || submitting || !submittable) return;

        const form = e.target;

        const formData = new FormData(form);

        submitting = true;

        await fetchCreateUser(property, form.elements["email"].value, form.elements["role"].value);

        submitting = submittable = false;

        form.reset();
    }

</script>
{#if property}
<form on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
        <ul>
            <li><label for="new-user-email">Email</label><input id="new-user-email" name="email" type="email" placeholder="required" required multiple /></li>
            <li><label for="new-user-role">Access</label><select id="new-user-role" name="role">
                <option value="user">view only</option>
                <option value="admin">full access</option>
            </select></li>
        </ul>
    </fieldset>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Grant Access"}</button>
    </footer>
</form>
{/if}