export function help(e) {
    if(!window.Intercom) return; // fall through
    Intercom("show");
    e.preventDefault();
}

export function message(text) {
    return function handler(e) {
        if(!window.Intercom) return; // fall through

        Intercom("showNewMessage", text);

        e.preventDefault();
    }
}