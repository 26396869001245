<script>
    import PolicyDisplay from "./PolicyDisplay.svelte";
    import { query } from "../util/router";

    export let policies;
    export let policy;
    
    let id;
    
    $: if(!id && policy && policy.id) id = policy.id;

    $: query("policy", id);


</script>
 <section class="display">
    <header>
        <h1>{(policy && policy.title) || "Loading…"}
        <select bind:value="{id}">
            {#if policies}
            {#each policies as policy}
            <option value="{policy.id}">{policy.title}</option>
            {/each}
            {/if}
        </select>
    </h1>
    </header>
    <PolicyDisplay policy={policy} />
</section>