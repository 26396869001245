import { get } from "lodash-es";

export function pricesFromPolicy(policy) {
    if(!policy) return;
    return Object.values(get(policy, "pricing.prices.items") || get(policy, "pricing.prices") || get(policy, "prices.items") || {});
}

export function metersFromPolicy(policy) {
    if(!policy) return;
    return Object.values(get(policy, "metered.meters.items") || get(policy, "metered.meters") || get(policy, "meters.items") || {});
}