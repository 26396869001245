<script>

    import { get, set, merge, defaultsDeep, map, filter, each, uniq, invokeMap, split } from "lodash-es";
    import { fetchCreateSend, fetchCSV } from "./api";

    export let property = null;
    export let tenants = null;
    export let sent = null;

    let submittable = false;
    let submitting = false;

    let contacts = {
    };

    let sends = {

    };

    let file = null;

    async function updateSends(json) {
        if(!json) return;
        json = await json;
        if(!json) return;

        for(const id in json.sent.items) {
            var item = json.items[id];
            if(item) set(sends, [ item.subject, item.id ], item);
        }

        sends = sends;
    }

    //$: console.log("sent=", sent);
    //$: if(sent) sent.then(json => console.log("sent json=", json));
    $: updateSends(sent);
    // $: if(sent) async() => {
    //     const json = await sent;
    //     sends = merge(sends, json.items);
    // }
    //$: if(sent) sent.then(json => console.log("sent json=", json));
    //$: if(sent) sent.then(json => json && each(json.items, (sentMap, tenantID) => sends[tenantID] = merge(sends[tenantID] || {}, sentMap)));
    // $: if(sent) {
    //     sent.then(json => json && each(sent.items, (sentMap, tenantID) => sends[tenantID] = merge(sends[tenantID] || {}, sentMap)));
    //     sends = sends;
    // }
    $: file && processCsvFile(file);
    $: submittable = Object.values(contacts).find(values => values && values.length);

    function values(text) {
        if(!text) return [];
        return splitToValues(text).filter(item => maybeEmail(item) || maybePhone(item));
    }

    function splitToValues(text) {
        if(!text) return [];
        return uniq(invokeMap(split(text, /[\r\n,;]/), String.prototype.trim)).filter(item => !!item);
    }

    function normalize(str) {
        return !str || str.replace(/[^a-zA-Z0-9]/gi, "").toUpperCase();
    }

    async function processCsvFile(file) {

        if(!file) return;

        let input = file;

        //console.log("process=", file);

        if(file.type !== "text/csv" && file.name.indexOf(".csv") < 0) input = await fetchCSV(file);

        Papa.parse(input, {

            header:false,
            complete: function(results) {

                //console.log(results);

                results.data.forEach(item => {

                    let unitFieldNameOrKey = null;
                    let tenant = null;

                    // check for explicit unit field
                    if(!!item.unit) {
                        unitFieldNameOrKey = "unit";
                        tenant = tenants.find(t => t.key === normalize(item.unit));
                    }

                    // find the first field that matches a unit
                    each(item, (value, key) => {
                        if(!!tenant || !!unitFieldNameOrKey) return;

                        const normalized = normalize(value);
                        
                        // take the exact normalized value first
                        tenant = tenants.find(t => t.key === normalized) || tenants.find(t => t.key === ("0"+normalized) || (normalized && normalized[0] === "0" && t.key === (normalized.substring(1))));

                        if(!!tenant) unitFieldNameOrKey = key;

                        //console.log("identified unit field?", unitFieldNameOrKey, tenant);

                    });

                    if(!tenant) return;

                    // create the record
                    const currentValues = contacts[tenant.id] || [];

                    each(item, (value, key) => {

                        if(key === unitFieldNameOrKey) return; // skip tenant one

                        values(value).forEach(v => currentValues.push(v));


                    });

                    contacts[tenant.id] = uniq(currentValues);

                });

                contacts = contacts;

                //console.log(results);
            }

        });

    }

    function files(e) {

        //console.log(e);

        var items = get(e, "target.files") || get(e, "dataTransfer.files") || [];
        //console.log(items);
        if(!items.length) return;

        for(let item of items) {
            if(item.type !== "text/csv" && item.name.indexOf(".csv") < 0 && item.name.indexOf(".xlsx") < 0  && item.name.indexOf(".xls") < 0) continue;
            file = item;
        }

        if(e.target) e.target.value = "";


        e.preventDefault();

    }

    async function submitAll(e) {

        e.preventDefault();

        if(submitting || !submittable) return;

        submitting = true;

        for (let [ id, values ] of Object.entries(contacts)) {
            
            if(!values.length) continue;

            var data = new FormData();
            data.append("subject", id);
            data.append("context", "amenity");
            uniq(values).forEach(line => data.append("to", line));

            const json = await fetchCreateSend(data);
            //console.log(json);

            contacts[id] = []; // clear
            //sends[id] = sends[id] || {};

            // copy to sent for id
            //json.send.created.map(id => json.items[id]).forEach(send => set(sends, [ id, send.id ], send));
            updateSends(json);

        }

        contacts = contacts;
        //sends = sends;

        submittable = submitting = false;

    }

    function contactTextChange(id, text) {
        if(!id) return;

        contacts[id] = values(text);

        //console.log(id, contacts[id], contacts);
    }

    function dragstart(e) {

        if(!e || !e.dataTransfer || !e.dataTransfer.types.includes("text/csv")) return;

        e.preventDefault();
        e.dataTransfer.dropEffect = "move";

    }
    function dragstop(e) {

    }

    /**
     * Checks to see if the input parameter is maybe an email.
     * @param {any} val - The value to check
     * @returns {boolean} indicating if the input might be an email.
     */
    function maybeEmail(val) {
        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return re.test(val);
    }

    /**
     * Checks to see if the input parameter is maybe a phone number.  This is a super crude check.  It pulls out the
     * numeric digits and count them to see if there are 10 or 11 numbers.
     *
     * @param {any} val - The value to check.
     * @returns {boolean} boolean indicating if it might be a phone number.
     */
    function maybePhone(val) {
        const includesLetters = (/[a-zA-Z]/gi).test(val);
        if(includesLetters) return false;
        const maybeDigits = `${val || ''}`.replace(/[^0-9]+/g, '');
        return maybeDigits.length == 10 || maybeDigits.length == 11;
    }

</script>

<!-- <textarea on:input={data}></textarea> -->
{#if !!tenants && !!tenants.length}
<table class="tenants" data-count={get(tenants, "length", "")}>
    <thead>
        <tr>
            <th scope="col">Unit</th>
            <th scope="col">Passcode</th>
            <th scope="col">Send To</th>
        </tr>
    </thead>
    <tbody>
    {#each tenants as tenant}
        {#if tenant}
        <tr>
            <th scope="row"><data class="{tenant.type} id {[ tenant.format, tenant.size ].filter(i => !!i).join(" ")}" value="{tenant.id}">{tenant.display}</data></th>
            <td><span>{tenant.authcode || ""}</span></td>
            <td>
                <ul>
                {#each map(get(sends, tenant.id, {})) as sent}
                    <li>{get(sent, "email[0]") || get(sent, "tel[0]")}</li>
                {/each}
                </ul>
                <textarea cols="20" rows="4" placeholder="one phone or email per line" on:change={e => contactTextChange(tenant.id, e.target.value)} required>{get(contacts, tenant.id, []).filter(i => !!i).join("\r\n")}</textarea>
            </td>
        </tr>
        {/if}
    {/each}
    </tbody>
</table>
<form on:submit={submitAll} class="contacts">
    <!-- <header><h1>Upload Contact List</h1></header> -->
    <!-- <label for="contacts-file"></label> -->
    <!-- <figure class="file">
        <input id="contacts-file" type="file" on:change={files} accept="text/csv,.csv,.xlsx,.xls"/>
        <figcaption>
            <h1>Select contact list to import</h1>
            
        </figcaption>
    </figure> -->
    <fieldset>
        <ul>
            <li>
                <input id="contacts-file" type="file" on:change={files} accept="text/csv,.csv,.xlsx,.xls"/>
                <label for="contacts-file">Select contact list to import</label>
            </li>
        </ul>
    </fieldset>
    <p><strong>Important: contact list must be in Excel or CSV format and have one column that matches your {get(property, "tenants.title", "").toLowerCase()}s exactly to match.</strong></p>
    <p>Please note: notices will be sent immediately and the recipient will be charged any data rates that apply.</p>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Sending" : "Send All" }</button>
    </footer>
</form>
{/if}