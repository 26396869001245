<script>
    import { fetchUpdateProperty } from "./api";
    import { get, clone } from "lodash-es";

    export let property = null;

    let editingProperty = null;

    let submittable = false;
    let submitting = false;

// save the first one we get
    $: if(!!property && (!submittable || !editingProperty)) editingProperty  = clone(property);

    $: newPhotoUrl = null;
    $: photoUrl = newPhotoUrl || (editingProperty && editingProperty.photo && (editingProperty.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/") + "?auto=compress,format&w=500"));

    function change(e) {
        submittable = e.target.form && e.target.form.checkValidity();
    }

    function photo(e) {
        const input = e.target;
        if(!input.files && !input.files[0]) return;
        const oldPhotoUrl = newPhotoUrl;
        newPhotoUrl = URL.createObjectURL(input.files[0]);
        if(oldPhotoUrl) URL.revokeObjectURL(oldPhotoUrl);
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting || !submittable || !editingProperty) return;

        const form = e.target;

        const formData = new FormData(form);

        submitting = true;

        // disable?


        const newPolicy = await fetchUpdateProperty(editingProperty, formData);

        submitting = submittable = false;
        editingProperty = null;

        if(newPhotoUrl) {
            const oldPhotoUrl = newPhotoUrl;
            newPhotoUrl = null;
            URL.revokeObjectURL(oldPhotoUrl);
        }

    }

</script>
{#if editingProperty}
<form on:change={change} on:input={change} on:submit={submit} class="edit property">

    <fieldset>
        <ul>
            <li><figure>
                <img class="property photo" alt="property" src="{photoUrl}" />
            </figure></li>
            <li>
                <input id="property-photo-new" type="file" accept="image/*" name="photo" on:change={photo} disabled={submitting} title="Select photo" />
                <label for="property-photo-new">Select photo</label>
            </li>
        </ul>
    </fieldset>

    <!-- <fieldset>
        <ul>
            <li><label for="property-title">Name</label><input id="property-title" name="name" type="text" value="{get(editingProperty, "name")}" required/></li>
        </ul>
    </fieldset> -->
        
    <fieldset>
        <p>Management office contact (public):</p>
        <ul>
            <li><label for="property-contact-name">Name</label><input id="property-contact-name" name="contact.name" type="text" value="{get(editingProperty, "contact.name", "")}" placeholder="optional"/></li>
            <li><label for="property-contact-email">Email</label><input id="property-contact-email" name="contact.email" type="text" value="{get(editingProperty, "contact.email", "")}" placeholder="optional"/></li>
            <li><label for="property-contact-tel">Phone</label><input id="property-contact-tel" name="contact.tel" type="text" value="{get(editingProperty, "contact.tel", "")}" placeholder="optional"/></li>
        </ul>
    </fieldset>
    

    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save" }</button>
    </footer>
</form>
{/if}