<script>
    import {  clone } from "lodash-es";
    import { parse, addDays, addMinutes, startOfDay, startOfHour } from "date-fns";
    import { fetchCreatePolicyMeter as update } from "./api";
    import { format } from "date-fns-tz";

    export let policy = null;

    let submittable = false;
    let submitting = false;

    let editingPolicy = null;

    let scheduledInterval = [ startOfHour(new Date()), startOfHour(new Date()) ];

    // save the first one we get
    $: if(!!policy && (!editingPolicy || !submittable)) editingPolicy  = clone(policy);

    function change(e) {
        // because changes recalculate the inputs, it can nuke out form
        submittable = !e.target.form || e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!editingPolicy || submitting || !submittable) return;

        submitting = true;

        const form = e.target;

        const formData = new FormData(form);

        await update(policy, formData);

        // clear the state
        submitting = submittable = false;
        editingPolicy = null;

        //form.reset();

        scheduledInterval = [ startOfHour(new Date()), startOfHour(new Date()) ];

    }

    function startDate(e) {
        scheduledInterval[0] = parse(`${e.target.value}T${format(scheduledInterval[0], "HH:mm")}`, "yyyy-MM-dd'T'HH:mm", new Date(e.target.value));
        scheduledInterval = scheduledInterval;
    }

    function startTime(e) {
        scheduledInterval[0] = parse(`${format(scheduledInterval[0], "yyyy-MM-dd")}T${e.target.value}`, "yyyy-MM-dd'T'HH:mm", scheduledInterval[0]);
        scheduledInterval = scheduledInterval;
    }

    function endDate(e) {
        scheduledInterval[1] = parse(`${e.target.value}T${format(scheduledInterval[1], "HH:mm")}`, "yyyy-MM-dd'T'HH:mm", new Date(e.target.value));
        scheduledInterval = scheduledInterval;
    }

    function endTime(e) {
        scheduledInterval[1] = parse(`${format(scheduledInterval[1], "yyyy-MM-dd")}T${e.target.value}`, "yyyy-MM-dd'T'HH:mm", scheduledInterval[1]);
        scheduledInterval = scheduledInterval;
    }
    
</script>
<form on:change={change} on:input={change} on:submit={submit}>
    <input type="hidden" name="concurrent" value="0" />
    <input type="hidden" name="valid" value="{format(scheduledInterval[0], "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: editingPolicy.timezone })}/{format(scheduledInterval[1], "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: editingPolicy.timezone })}" />
    {#if editingPolicy} 
    <fieldset>
        <ul>
            <li>
                <label>Starting</label><select value={format(scheduledInterval[0], "yyyy-MM-dd")} on:blur={startDate} on:change={startDate}>
                {#each Array.from({length:365}, (v, i) => addDays(new Date(), i)) as date}
                <option value="{format(date, "yyyy-MM-dd")}">{format(date, "EE MMM d")}</option>
                {/each}
            </select><select value={format(scheduledInterval[0], "HH:mm")} on:blur={startTime} on:change={startTime}>
                {#each Array.from({length:48}, (v, i) => addMinutes(startOfDay(new Date()), i * 30)) as date}
                <option value="{format(date, "HH:mm")}">{format(date, "h:mm a")}</option>
                {/each}
            </select></li>
            <li>
                <label>Ending</label><select value={format(scheduledInterval[1], "yyyy-MM-dd")} on:blur={endDate} on:change={endDate}>
                {#each Array.from({length:365}, (v, i) => addDays(new Date(), i)) as date}
                <option value="{format(date, "yyyy-MM-dd")}">{format(date, "EE MMM d")}</option>
                {/each}
            </select><select value={format(scheduledInterval[1], "HH:mm")} on:blur={endTime} on:change={endTime}>
                {#each Array.from({length:48}, (v, i) => addMinutes(startOfDay(new Date()), i * 30)) as date}
                <option value="{format(date, "HH:mm")}">{format(date, "h:mm a")}</option>
                {/each}
            </select>
        </li>
        </ul>
    </fieldset>
    
    <p>Please note: scheduled closures will only apply to new passes and any existing passes will remain active until cancelled.</p>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Schedule Closure"}</button>
    </footer>
    {/if}
</form>