<script>
    import * as d3 from "d3";

    export let scale;
    export let height;
    export let position;
    export let margin;
    export let tickFormat;
    
    let g;
    let transform;
 
    $: {
        let group = d3.select(g);
        group.selectAll("*").remove();

        let axis;
        switch (position) {
            case "bottom":
                axis = d3.axisBottom(scale).tickSizeOuter(0).tickSize(0);
                if (tickFormat) {
                    axis.tickFormat(tickFormat);
                }
                transform = `translate(${margin.left}, ${height - margin.bottom})`;
                break;
            case "left":
                axis = d3.axisLeft(scale)
                    .tickFormat(d3.format("d"))
                    .tickSizeOuter(0)
                    .tickSizeInner(0)
                    .tickValues(scale.domain());
                transform = `translate(${margin.left}, 0)`;
                break;
        }

        group.call(axis);
    }

</script>

<g bind:this={g} class="chart-axis" {transform} />