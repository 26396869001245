<script>

import { propertyPaymentMetrics, policies} from "../stores";
import PaymentMetrics from "./PaymentMetrics.svelte";
export let property;

$: pricingPolicies = ($policies || []);// && $policies.filter(item => item.pricing)) || []
$: disabled = ($policies && !pricingPolicies.filter(item => item.pricing).length);

</script>
<section>
<PaymentMetrics data={$propertyPaymentMetrics} property={property} />
<ul class="pricing">
    {#each pricingPolicies as policy}
    <li>
        <h1>{policy.title}</h1>
        <p>{policy.pricing ? policy.pricing.description : "Free"}</p>
    </li>
    {/each}
</ul>
{#if disabled}
<aside class="upgrade payments">
    <h1>Payments are in early-release!</h1>
    <p>We're rolling out our awesome new payment and deposit features available to a limited number of communities before the full launch. If you're interested, please let us know:</p>
    <!-- <p>It looks like you’re not currently using <a href="https://www.luminousresidential.com/products/parkingboss?utm_source=amenitymanager-web&utm_campaign=amenitiesparking" target="_blank">Parking Boss</a> to manage guest and resident parking. Get in touch to find the best plan for your community.</p> -->
    <nav>
        <!-- <a href="https://www.luminousresidential.com/products/parkingboss?utm_source=amenitymanager-web&utm_campaign=amenitiesparking" target="_blank">Learn More</a> -->
        <a href="mailto:help@luminousresidential.com?subject=Amenity Payments&body=Interested in being a payments early adopter" on:click={message }>I'm Interested…</a>
    </nav>
    <figure>
        <img src="/payments.png" />
    </figure>
</aside>
{/if}
</section>