import { router, end } from "./util/router";
import App from "./App.svelte";
import style from "../styles/app.scss";

import { api } from "./api";

window.API = api;


const app = window.app = new App({ target: document.body, props: {} });

//querystring
router("*", function(ctx, next) {
    if(window.Intercom) window.Intercom("update", {
        last_request_at: (new Date()).getTime()/1000
    });
    next();
});

function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

//page("/properties/:property/*", state);
//page.redirect("/", "/properties/95n6f1jgb52d30hg2xdmepdj0g");
router("/properties/:property", ctx => router(`/properties/${ctx.params.property}/live`));

router("/p/:permit", view("permit"), end);
router("/permits/:permit", view("permit"), end);

router("/properties/:property/permits/new", view("newpermit"), end);

// policy catch all
//page("/properties/:property/amenities/:policy/*", state);
//page("/properties/:property/amenities/:policy/permits/new", view("newpermit"), end);

// policy home
router("/properties/:property/amenities/new", view("newpolicy"), end);
router("/properties/:property/amenities/settings", view("policysettings"), end);
//page("/properties/:property/amenities/:policy/settings", view("policysettings"), end);
//page("/properties/:property/amenities/:policy/:subview", view("policy"), end);
router("/properties/:property/amenities/:policy/edit", view("editpolicy"), end);
router("/properties/:property/amenities/:policy", view("policy"), end);

router("/properties/:property/amenities", view("policies"), end);

// property subview
router(`/properties/:property/:view`, end);

router("/properties/:property/metrics/:policy", view("policymetrics"), end);

// index 
router(`*`, view("index"), end);

router.start();