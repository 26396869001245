<script>

import { get } from "lodash-es";
import EditPolicySettings from "./EditPolicySettings.svelte";
import EditPolicyLimits from "./EditPolicyLimits.svelte";
import EditPolicyClosedSchedule from "./EditPolicyClosedPattern.svelte";
import EditPolicyClosedEventMetersNew from "./EditPolicyClosedEventNew.svelte";
import EditPolicyClosedEventMeters from "./EditPolicyClosedEventMeters.svelte";
import EditPolicyUnits from "./EditPolicyUnits.svelte";
import EditPolicyMeters from "./components/EditPolicyMeters.svelte";

import EditPolicyPasses from "./EditPolicyPasses.svelte";
import DeletePolicy from "./DeletePolicy.svelte";
import { units } from "./stores";

export let property = null;
export let policy = null;
export let issue = true;
export let disabled = false;

</script>
{#if policy}
    <EditPolicySettings policy={policy} bind:issue={issue} bind:disabled={disabled} />
    <!-- {#if !disabled} -->
        {#if issue || disabled}
            <EditPolicyPasses policy={policy} />
        {/if}
        <EditPolicyLimits policy={policy} issue={issue || disabled} />
        {#if issue || disabled}
        <EditPolicyMeters principals={get(property, "units.meters.principal", false) ? $units : false } policy={policy} />
        {/if}
        {#if issue && get(policy, "tenant.request", false)}
            <EditPolicyUnits policy={policy} />
        {/if}
        {#if issue || disabled}
            <EditPolicyClosedSchedule policy={policy} />
            <section>
                <header><h1>Special Closures</h1></header>
                <EditPolicyClosedEventMetersNew policy={policy} />
                <EditPolicyClosedEventMeters policy={policy} />
            </section>
            <!-- <section>
                <header><h1>Schedule</h1></header>
                <DateTimeSchedule timezone={policy.timezone} interval={policy.schedule.week.interval}, values={policy.schedule.week.intervals} />
            </section> -->
        {/if}
    <!-- {/if} -->
    <DeletePolicy policy={policy} />
{/if}