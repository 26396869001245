<script>
import { get, filter, flatMap, clone } from "lodash-es";
    import { fetchUpdatePolicyValidIntervalMax } from "./api";
    import page from "page";

    export let policy = null;

    let submittable = false;
    let submitting = false;

    let editingPolicy = null;

    // save the first one we get
    //$: if(!!policy && !submittable) editingPolicy  = clone(policy);
    //$: if(!!policy && !editingPolicy) editingPolicy = clone(policy);
    $: if(!!policy && (!editingPolicy || !submittable)) editingPolicy  = clone(policy);

    function change(e) {
        // because changes recalculate the inputs, it can nuke out form
        ///console.log("changed form", e.target.form);
        submittable = !e.target.form || e.target.form.checkValidity();
        //console.log("changed", submittable, e.target);
    }

    async function submit(e) {

        e.preventDefault();

        if(!window.confirm("This is permanent and cannot be undone. Continue?")) return;

        if(!editingPolicy || submitting || !submittable) return;

        submitting = true;

        const form = e.target;

        await fetchUpdatePolicyValidIntervalMax(editingPolicy);

        
        // clear the state
        submitting = submittable = false;

        page(`/properties/${editingPolicy.scope}/amenities`);

        editingPolicy = null;

    }
</script>
{#if editingPolicy}
<form class="delete" on:submit={submit}>
    <header>
        <h1>Remove Amenity</h1>
    </header>
    <fieldset>
        <ul>
            <li>
                <label for="policy-remove-confirm">Remove</label>
                <select id="policy-remove-confirm" name="confirm" on:change={e => (submittable = (e.target.value === "true"))}>
                    <option value="false">no</option>
                    <option value="true">yes, permanently remove</option>
                </select>
            </li>
        </ul>
    </fieldset>
    <p><strong>Important: removing an amenity is permanent and cannot be undone.</strong></p>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Removing" : "Remove"}</button>
    </footer>
</form>
{/if}