<script>
    import { utcToZonedTime } from "date-fns-tz";
    import { property, validDefaultPropertyToday as valid, propertyNow, user, tenantId, policyId, policies } from "./stores";
    import { get, capitalize, find, merge, pick } from "lodash-es";
    import { parseISO, format, addMinutes, isWithinInterval } from "date-fns";
    import { downloadHandler } from "./util/exportTable";
    import ValidInput from "./ValidInput.svelte";
    import { fetchDeletePermit, fetchUpdatePermit } from "./api";
    import UnitsTenantsSelect from "./components/UnitsTenantsSelect.svelte";
import ValidMaxInput from "./components/ValidMaxInput.svelte";
import ValidMinInput from "./components/ValidMinInput.svelte";
import { query } from "./util/router";
import PolicySelect from "./components/PolicySelect.svelte";
    export let permits = null;

    let table = null;

    async function cancel(permit) {
        if(!window.confirm("This is permanent and cannot be undone. Continue?")) return;
        const json = await fetchDeletePermit(permit);
        // need to update
        console.log("update", permits, permits.items);
        const previous = find(get(await permits, "items"), { id: permit.id });
        const next = get(json, [ "items", permit.id ]);
        console.log("update", previous, next);
        if(previous && next) { 
            merge(previous, pick(next, [ "cancelled", "valid", "revoked", "updated", "generated" ]));
            permits = permits;
        }
        
    }
    async function revoke(permit) {
        if(!window.confirm("This is permanent and cannot be undone. Continue?")) return;
        const data = new FormData();
        data.append("valid.maximum", addMinutes(new Date(), -1).toISOString());
        const json = await fetchUpdatePermit(permit, data);
        // need to update
        console.log("update", permits, permits.items);
        const previous = find(get(await permits, "items"), { id: permit.id });
        const next = get(json, [ "items", permit.id ]);
        console.log("update", previous, next);
        if(previous && next) { 
            merge(previous, pick(next, [ "cancelled", "valid", "revoked", "updated", "generated" ]));
            permits = permits;
        }
        
    }
    async function exempt(permit) {
        if(!window.confirm("This is permanent and cannot be undone. Continue?")) return;
        const data = new FormData();
        data.append("exempt", "true");
        const json = await fetchUpdatePermit(permit, data);
        // need to update
        console.log("update", permits, permits.items);
        const previous = find(get(await permits, "items"), { id: permit.id });
        const next = get(json, [ "items", permit.id ]);
        console.log("update", previous, next);
        if(previous && next) { 
            merge(previous, pick(next, [ "cancelled", "valid", "revoked", "updated", "generated" ]));
            permits = permits;
        }
        
    }

    

    $: timezone = $property && $property.timezone;

    $: console.log(timezone);

    let interval;
    let tenant;
    let policy;

    $: if($policyId) policy = $policyId;
    $: if($valid) interval = $valid;
    $: if($tenantId) tenant = $tenantId;

    function update(e) {
        e.preventDefault();
        let form = e.target;

        if(!e.target.checkValidity()) return;

        query({
            valid: interval,
            tenant: tenant,
            policy: policy,
        });
        

    }
    
</script>
<form on:submit|preventDefault={update}>
    <fieldset>
        <ul>
            <li><label for="permits-tenant">Unit</label><UnitsTenantsSelect id="permits-tenant" bind:value={tenant} /></li>
            <li><label for="permits-policy">Amenity</label><PolicySelect id="permits-policy" policies={$policies} bind:value={policy} /></li>
        </ul>
    </fieldset>
    <fieldset>
        <ul>
            <li><label>From</label><ValidMinInput bind:value={interval} timezone={timezone} /></li>
            <li><label>To</label><ValidMaxInput bind:value={interval} timezone={timezone} /></li>
        </ul>
    </fieldset>
    <footer>
        <button type="submit">Update</button>
    </footer>

</form>
{#await permits}
   <table>
        <caption>
        </caption>
   </table>
{:then permits}
    <table class="permits" data-count="{get(permits.items, "length", "")}" bind:this={table}>
        <!-- <caption>
            <a href="#" download="{get($property, "name", "")} Pass Log.csv" on:click={downloadHandler}>Export</a>
        </caption> -->
        <thead>
            <tr>
                <th scope="col">Amenity</th>
                <th scope="col">Pass Type</th>
                <th scope="col">Unit</th>
                <th scope="col">Name</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Canceled</th>
                <th scope="col">Registered</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Usage Fee</th>
                <th scope="col">Deposit</th>
                <th scope="col">Time Limits</th>
                {#if get($user, "roles.admin", false)}
                <th scope="col"></th>
                {/if}
            </tr>
        </thead>
        <tbody>

            {#each permits.items as permit}
                <tr>
                    <th scope="row"><h1>{get(permit, "title")}</h1></th>
                    <td><h2>{!!permit.name ? "Person" : `${capitalize(get(permit, "tenant.format", "Unit"))}`}</h2></td>
                    <td>{#if !!permit.tenant}
                        <data class="{permit.tenant.type} id {[ permit.tenant.format, permit.tenant.size ].filter(i => !!i).join(" ")}" value="{permit.tenant.id}">{permit.tenant.display}</data>
                    {/if}</td>
                    <td><h2>{permit.name || permit.description || ""}</h2></td>
                    <td><time datetime="{get(permit, "valid.min.utc")}">{format(utcToZonedTime(get(permit, "valid.min.utc"), permit.timezone), "h:mm a EE MMM d yyyy")}</time></td>
                    <td><time datetime="{get(permit, "valid.max.utc")}">
                        {#if permit.cancelled}
                        Canceled
                        {:else}
                        {format(utcToZonedTime(get(permit, "valid.max.utc"), permit.timezone), "h:mm a EE MMM d yyyy")}
                        {/if}</time></td>
                    <td><time datetime="{get(permit, "revoked.utc")}">{permit.cancelled ? format(utcToZonedTime(get(permit, "revoked.utc"), permit.timezone), "h:mm a EE MMM d yyyy") : ""}</time></td>
                    <td><time datetime="{get(permit, "issued.utc")}">{format(utcToZonedTime(get(permit, "issued.utc"), permit.timezone), "h:mm a EE MMM d yyyy")}</time></td>
                    <td>{get(permit, "contact.tel") || get(permit, "contact.phone", "")} </td>
                    <td>{get(permit, "contact.email", "")}</td>
                    <td>
                        <ul>
                        {#each permit.attachments.filter(item => item.type == "fee" && !item.contingent) as fee}
                            <data class="fee" value="{fee.id}">{fee.total.display}</data>
                        {/each}
                        </ul>
                    </td>
                    <td>
                        <ul>
                        {#each permit.attachments.filter(item => item.type == "fee" && item.contingent) as fee}
                            <data class="fee" value="{fee.id}">{fee.total.display}</data>
                        {/each}
                        </ul>
                    </td>
                    <td>{get(permit, "cancelled", false) || get(permit, "valid.exempt", false) ? "--" : "Counted"}</td>
                    {#if get($user, "roles.admin", false)}
                    <td>
                    {#if !permit.cancelled && permit.valid.to && (new Date() < new Date(permit.valid.from))} <button type="button" on:click={e => (cancel(permit))}>Cancel Pass</button>{:else if !permit.cancelled && !get(permit, "valid.exempt", false)} <button type="button" on:click={e => (exempt(permit))}>Credit Time</button>{/if}
                    </td>
                    {/if}
                </tr>
            {/each}
        </tbody>
        <tfoot>
            <tr>
                <th colspan="8">
                    <!-- <UnitsTenantsSelect /><ValidInput value={$valid || $propertyNow} /> -->
                    <a href="#" download="{get($property, "name", "")} Pass Log.csv" on:click={e => downloadHandler(e, table)}>Download Spreadsheet</a></th>
            </tr>
        </tfoot>
    </table>
{:catch error}
	<p style="color: red">{error.message}</p>
{/await}