<script>
    import DayTimeInput from "./DayTimeInput.svelte";
    import { clone } from "lodash-es";
    import { fetchUpdatePolicyClosed } from "./api";

    import { metersFromPolicy } from "./util/policy";

    export let policy = null;

    let submittable = false;
    let submitting = false;

    let editingPolicy = null;

    // save the first one we get
    //$: if(!!policy && !submittable) editingPolicy  = clone(policy);
    //$: if(!!policy && !editingPolicy) editingPolicy = clone(policy);
    $: if(!!policy && (!editingPolicy || !submittable)) editingPolicy  = clone(policy);

    $: console.log("editing policy closed=", editingPolicy);

    // these are valid and have a daytime
    $: valid = editingPolicy && (metersFromPolicy(editingPolicy) || []).filter(item => {
        // must be concurrent, 0, daytime valid, and open ended validity
        // closed ended validity or no daytime are considered scheduled closes
        return item && item.format === "concurrent" && item.concurrent === 0 && !!item.valid.daytime && !!item.valid.daytime.length && item.valid.interval.endsWith("/");
    }).flatMap(item => item.valid.daytime);
    $: console.log("closed.valid=", valid);


    function change(e) {
        // because changes recalculate the inputs, it can nuke out form
        ///console.log("changed form", e.target.form);
        submittable = !e.target.form || e.target.form.checkValidity();
        //console.log("changed", submittable, e.target);
    }

    async function submit(e) {

        e.preventDefault();

        if(!editingPolicy || submitting || !submittable) return;

        submitting = true;

        const form = e.target;

        const formData = new FormData(form);

        await fetchUpdatePolicyClosed(editingPolicy, formData);

        
        // clear the state
        submitting = submittable = false;
        editingPolicy = null;

    }
    
</script>
<form on:change={change} on:input={change} on:submit={submit}>
    <header>
        <h1>Open & Closed Schedule</h1>
    </header>
    
    {#if editingPolicy}
    <input type="hidden" name="concurrent" value="0" />
    <DayTimeInput valid={valid} />
    <p>Please note: changing scheduled open & closed times will only apply to new passes and any existing passes will remain active until cancelled.</p>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save"}</button>
    </footer>
    {/if}
</form>