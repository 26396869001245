<script>

    import { units } from "./stores";
    import { fetchUpdatePolicy } from "./api";
    import { get, range, filter, map, size, each, clone, find } from "lodash-es";

    export let policy = null;

    let submittable = false;
    let submitting = false;

    let editing = null;

    //$: editing = policy;
    $: if(!!policy && (!editing || !submittable)) editing  = clone(policy);

    $: selectedItems = map(get(editing, "tenant.items", {}));
    $: console.log(selectedItems);
    $: allItems = map(get(editing, "tenant.items", {})).length === 0;
    $: singleItem = map(get(editing, "tenant.items", {})).length === 1;

    function change(e) {
        submittable = e.target.form && e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!editing || submitting || !submittable) return;

        submitting = true;

        const form = e.target;

        const formData = new FormData(form);

        await fetchUpdatePolicy(editing, formData);

        submitting = submittable = false;
        editing = null;

    }

</script>
<form on:change={change} on:input={change} on:submit={submit}>
    <header>
        <h1>Unit Access</h1>
    </header>

    <fieldset>
        <ul>
            <li>
                <label for="policy-unit-options">Allow</label>
                <select id="policy-unit-options" on:blur={e => { allItems = e.target.value === "all"; singleItem = e.target.value == "single"; }} on:change={e => { allItems = e.target.value === "all";singleItem = e.target.value == "single"; }} value="{allItems ? "all" : singleItem ? "single" : "select"}">
                    <option value="all">all units</option>
                    <option value="single">single unit…</option>
                    <option value="select">select units…</option>

                </select>
            </li>
            {#if singleItem}
            <li>
                <label for="policy-unit-options">Selected</label>
                <select id="policy-unit-item" name="permit.tenant.items">
                    {#if $units}
                    {#each $units as item}
                        <option value="{item.display}" selected={selectedItems.includes(item.display) || selectedItems.includes(item.key)}>{item.display}</option>
                    {/each}
                    {/if}

                </select>
            </li>
            {:else if !allItems}
            <li>
                <label for="policy-unit-items">Selected</label>
                <ul>
                {#if $units}
                    {#each $units as item}
                        <li>
                            <input id="unit-options-{item.id}" type="checkbox" name="permit.tenant.items" checked={selectedItems.length === 0 || selectedItems.includes(item.display) || selectedItems.includes(item.key)} value="{item.display}" /> <label for="unit-options-{item.id}"><data class="id {[ item.format, item.type ].join(" ")}">{item.display}</data></label>
                        </li>
                        
                    {/each}
                    {/if}
                </ul>
            </li>
            {/if}
            <!-- {#if !allItems}
            <li>
                <label for="policy-unit-list">List</label>
                <select id="policy-unit-list" name="tenant.list.all" value="{get(editing, "tenant.list.all", true)+""}" disabled>
                    <option value="true">all</option>
                    <option value="false">with access only</option>
                </select>
            </li>
            {/if} -->
        </ul>
    </fieldset>

    <!-- <fieldset>
        <ul>
            <li>
                <label for="policy-unit-options">Access</label>
                <select id="policy-unit-options" on:blur={e => { allItems = e.target.value === "true"; }} on:change={e => { allItems = e.target.value === "true"; }} value="{allItems+""}">
                    <option value="true">all {get(editing, "tenant.format", "unit")}s</option>
                    <option value="false">select {get(editing, "tenant.format", "unit")}s</option>

                </select>
            </li>
            {#if !allItems}
            <li>
                <label for="policy-unit-items">Selected</label>
                <ul>
                    {#if $units}
                    {#each $units as unit}
                
                        <li>
                            <input id="tenant-options-{unit.id}" type="checkbox" name="permit.tenant.items" checked={selectedItems.length === 0 || selectedItems.includes(unit.key)} value="{unit.key}" /> <label for="tenant-options-{unit.id}"><data class="id {[ unit.format, unit.type ].join(" ")}">{unit.display}</data></label>
                        </li>
                        
                    {/each}
                    {/if}
                </ul>
            
            </li>
            {/if}
        </ul>
    </fieldset> -->

    <input type="hidden" name="permit.tenant.items" value="" />

    
    
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save"}</button>
    </footer>
</form>