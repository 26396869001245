<script>
    import { get, sortBy } from "lodash-es";
    import { stringToDates } from "./util/datetime";
    import Permit from "./Permit.svelte";

    export let permits = null;
    export let policy = null;
    export let valid = null;

    function distinct(a1, a2, b1, b2) {
        return (Math.max(a1, a2) < Math.min(b1, b2)) || (Math.min(a1, a2) > Math.max(b1, b2));
    }

    function adjacent(a1, a2, b1, b2) {
        return (Math.max(a1, a2) == Math.min(b1, b2)) || (Math.min(a1, a2) == Math.max(b1, b2));
    }

    function intersects(a1, a2, b1, b2, inclusive) {
        return !distinct(a1, a2, b1, b2) && (inclusive === true || !adjacent(a1, a2, b1, b2));
    }

    $: validInterval = valid && (valid.toISOString ? [ valid, valid ] : stringToDates(valid));
    //$: console.log(policy, validInterval);
    $: items = permits && permits.filter(item => !policy || (item.policy && item.policy.subject == policy.subject)).filter(item => !validInterval || intersects(...stringToDates(item.valid.interval), ...validInterval));
    //$: console.log("items=", items);
    $: issued = !!get(policy, "issue.enabled");

</script>
<ol class="permits" class:policy={!!policy} data-count="{get(items, "length", "")}" data-required="{issued.toString()}">
    {#if items}
    {#each sortBy(items, "issued.utc") as permit}
        <li>
            <Permit permit={permit} />
        </li>
    {/each}
    {/if}
</ol>