<script>
    import { fetchCreatePolicy } from "./api";
    import { each } from "lodash-es";
    import page from "page";

    export let property = null;


    let submittable = false;
    let submitting = false;
    let disabled = false;

    function change(e) {
        submittable = e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting || !submittable || !property) return;

        const form = e.target;

        const formData = new FormData(form);

        submitting = true;

        // disable?


        const newPolicy = await fetchCreatePolicy(property, formData);

        submitting = submittable = false;
            // route
        page(`/properties/${newPolicy.scope}/amenities/${newPolicy.policy}/edit`);


        submitting = submittable = false;

    }

</script>
{#if property}
<form on:change={change} on:input={change} on:submit={submit} class="new policy">
    <header>
        <h1>Add Amenity</h1>
    </header>

    <fieldset>
        <ul>
            <li><label for="new-policy-title">Name</label><input id="new-policy-title" name="title" type="text" required placeholder="required" /></li>
        </ul>
    </fieldset>
        
    <input type="hidden" name="template" value="amenity" />
    <!-- <fieldset>
        <ul class="toggle">
            <li><input id="new-policy-permits-true" type="radio" name="template" value="amenity" checked /><label for="new-policy-permits-true">Passes</label></li>
            <li><input id="new-policy-permits-false" type="radio" name="template" value="amenity-info" /><label for="new-policy-permits-false">Info Only</label></li>
            <li><input id="new-policy-permits-disabled" type="radio" name="template" value="amenity-closed" on:change={e => disabled = e.target.checked } /><label for="new-policy-permits-disabled">Closed</label></li>
        </ul>
    </fieldset> -->
    <p>Note: you can set up an amenity ahead of time and then close it until you are ready.</p>

    <!-- {#if disabled}
        <fieldset>
            <ul>
                <li>
                    <label for="policy-disabled">Message</label>
                    <textarea id="policy-disabled" name="disabled.reason" value="" required></textarea>
                </li>
            </ul>
        </fieldset>
    {/if} -->
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Continue" }</button>
    </footer>
</form>
{/if}