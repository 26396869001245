<script>
  import { normalize, toMilliseconds } from "duration-fns";
  import { fetchUpdatePolicy, fetchCreatePolicy } from "./api";
  import { get, range, filter, clone } from "lodash-es";
  import {
    formatDistanceStrict,
    addDays,
    parseISO,
    format,
    isBefore,
  } from "date-fns";

  export let policy = null;
  export let issue = true;
  export let disabled = false;

  const hourMS = 60 * 60 * 1000;

  let validType;
  let submittable = false;
  let submitting = false;

  // react only if in an unchanged state
  //$: editingPolicy = policy && !submittable ? clone(policy) : editingPolicy;
  let editingPolicy = null;

  // save the first one we get
  //$: if(!!policy && !editingPolicy) editingPolicy  = clone(policy);
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = clone(policy);

  $: name = !!get(policy, "name.request");

  let selectedMinDurationISO = null;

  $: minDurationISO =
    selectedMinDurationISO ||
    get(editingPolicy, "duration.min") ||
    get(editingPolicy, "duration") ||
    "PT30M";
  $: maxDurationISO =
    get(editingPolicy, "duration.max") ||
    get(editingPolicy, "duration") ||
    "PT1H";
  $: issue = !!get(editingPolicy, "issue.enabled");
  $: disabled = !!get(editingPolicy, "disabled");
  //$: unitType = get(editingPolicy, "tenant.format") || "unit";
  $: minDurationMS = toMilliseconds(minDurationISO);
  $: maxDurationMS = toMilliseconds(maxDurationISO);
  $: maxDurationValues = calculateMaxDuration(minDurationMS);
  //$: durationDays = (0 == (minDurationMS % (24 * 60 * 60 * 1000)));

  let minCount = 1;
  let maxCount = 1;

  let startTime;
  let endTime;

  $: if (!startTime)
    startTime =
      typeof get(editingPolicy, "start.time") == "string" &&
      get(editingPolicy, "start.time");
  $: if (!endTime)
    endTime =
      typeof get(editingPolicy, "end.time") == "string" &&
      get(editingPolicy, "end.time");

  $: initMinMaxDuration = !startTime && !endTime;

  //$: savedValidType = startTime && endTime && "startendtime";

  $: if (!validType && startTime && endTime) {
    validType = "startendtime";

    // calculate the min count/max count from duration
    minCount = Math.ceil(minDurationMS / (24 * hourMS));
    maxCount = Math.ceil(maxDurationMS / (24 * hourMS));
  }

  let minCountValues = [...new Array(30).keys()].map((i) => i + 1);

  $: maxCountValues =
    minCount && [...Array(30).keys()].map((i) => i + minCount);

  $: if (maxCount || minCount) maxCount = Math.max(maxCount, minCount);

  let calculatedDurationMinMS = 0;
  let calculatedDurationMaxMS = 0;

  //$: if(validType == "schedule") selectedMinDurationISO = "PT1H";

  $: if (
    validType == "startendtime" &&
    (startTime || endTime || minCount || maxCount)
  ) {
    // calculate the durations...
    const start = parseISO(
      format(new Date(), "yyyy-MM-dd") + "T" + (startTime || "00:00:00")
    );
    const end = parseISO(
      format(new Date(), "yyyy-MM-dd") + "T" + (endTime || "00:00:00")
    );

    if (end.getTime() < start.getTime()) {
      calculatedDurationMinMS =
        addDays(end, minCount).getTime() - start.getTime() - 60 * 60 * 1000; // add hour for dst
      calculatedDurationMaxMS =
        addDays(end, maxCount).getTime() - start.getTime() + 60 * 60 * 1000;
    } else {
      // bounded in same day
      calculatedDurationMinMS =
        addDays(end, minCount - 1).getTime() - start.getTime() - 60 * 60 * 1000; // add hour for dst
      calculatedDurationMaxMS =
        addDays(end, maxCount - 1).getTime() - start.getTime() + 60 * 60 * 1000;
    }
  }

  // clear on explicit change
  //$: if(validType == "schedule") startTime = endTime = null;

  //$: console.log("editingPolicy=", editingPolicy);
  //$: console.log("issue=", issue, "disabled=", disabled);

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await fetchUpdatePolicy(editingPolicy, formData);

    // clear the state
    submitting = submittable = false;
    editingPolicy = null;
  }

  const times = [
    0.25 * 60 * 60 * 1000,
    0.5 * 60 * 60 * 1000,
    0.75 * 60 * 60 * 1000,
    1.0 * 60 * 60 * 1000,
    1.25 * 60 * 60 * 1000,
    1.5 * 60 * 60 * 1000,
    1.75 * 60 * 60 * 1000,
    2.0 * 60 * 60 * 1000,
    2.5 * 60 * 60 * 1000,
    3.0 * 60 * 60 * 1000,
    3.5 * 60 * 60 * 1000,
    4.0 * 60 * 60 * 1000,
    5.0 * 60 * 60 * 1000,
    6.0 * 60 * 60 * 1000,
    7.0 * 60 * 60 * 1000,
    8.0 * 60 * 60 * 1000,
    10.0 * 60 * 60 * 1000,
    12.0 * 60 * 60 * 1000,
    14.0 * 60 * 60 * 1000,
    16.0 * 60 * 60 * 1000,
    18.0 * 60 * 60 * 1000,
    20.0 * 60 * 60 * 1000,
    22.0 * 60 * 60 * 1000,
    24.0 * 60 * 60 * 1000,
    36.0 * 60 * 60 * 1000,
    48.0 * 60 * 60 * 1000,
  ];

  function calculateMaxDuration(min) {
    const max = 24 * 60 * 60 * 1000;
    const items = [];
    let i = min;

    items.push(min);

    for (let i of times.filter((time) => time > min && time < min * 2)) {
      items.push(i);
      //min = i;
    }

    // first 8 steps then on the hour
    for (i = min * 2; i < max; i += min) {
      if (i <= 8 * min) items.push(i);
      else if (i % (60 * 60 * 1000) === 0) items.push(i);
    }

    return items;
  }

  function toISOString(ms) {
    const parsed = normalize(ms);
    //console.log("parsed", parsed);
    return (
      "PT" +
      (parsed.days > 0 || parsed.hours > 0
        ? parsed.days * 24 + parsed.hours + "H"
        : "") +
      (parsed.minutes > 0 ? parsed.minutes + "M" : "")
    );
  }

  function formatTime(ms) {
    const parsed = normalize(ms);
    return `${parsed.days * 24 + parsed.hours}:${parsed.minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function changeValidType(type) {
    if (validType == type) return; // no change

    // switching to schedule for the first time
    if (type == "schedule" && !initMinMaxDuration) {
      selectedMinDurationISO = "PT1H";
      initMinMaxDuration = true;
    }

    validType = type;
  }
</script>

{#if editingPolicy}
  <form on:change={change} on:input={change} on:submit={submit}>
    <header>
      <h1>Pass Settings</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="policy-field-tenant">Unit</label>
          <select
            id="policy-field-tenant"
            name="permit.tenant"
            value="true"
            disabled
          >
            <option value="true">required</option>
            <!-- <option value="false">Optional</option> -->
            <option value="">Disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-field-auth">Passcode</label>
          <select
            id="policy-field-auth"
            name="permit.authentication"
            value="true"
            disabled
          >
            <option value="true">required</option>
            <!-- <option value="false">Disabled</option> -->
          </select>
        </li>
        <!-- <li>
                <label for="policy-field-name">Pass is for</label>
                <select id="policy-field-name" name="permit.contact.name" value="{name || ""}" on:blur={e => (name = e.target.value === "true")} on:change={e => (name = e.target.value === "true")}>
                    <option value="true">one person</option>
                    <option value="">entire {get(editingPolicy, "tenant.format")}</option>
                </select>
            </li> -->

        {#if name}
          <li>
            <label>Name</label>
            <select name="permit.contact.name" value={name + ""} disabled>
              <option value="true">required</option>
              <option value="false">not applicable</option>
            </select>
          </li>
        {:else}
          <li>
            <label for="policy-field-notes">Names</label>
            <select
              id="policy-field-notes"
              name="notes"
              value={get(editingPolicy, "notes.required")
                ? "true"
                : get(editingPolicy, "notes.request")
                ? "false"
                : ""}
            >
              <option value="true">required</option>
              <option value="false">optional</option>
              <option value="">disabled</option>
            </select>
          </li>
        {/if}
        <li>
          <label for="policy-field-tel">Phone</label>
          <select
            id="policy-field-tel"
            name="permit.contact.tel"
            value={get(editingPolicy, "tel.required")
              ? "true"
              : get(editingPolicy, "tel.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>
            <!-- <option value="">hidden</option> -->
          </select>
        </li>
        <li>
          <label for="policy-field-email">Email</label>
          <select
            id="policy-field-email"
            name="permit.contact.email"
            value={get(editingPolicy, "email.required")
              ? "true"
              : get(editingPolicy, "email.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>

            <!-- <option value="">hidden</option> -->
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Time per pass:</p>
      {#if !validType || validType == "schedule"}
        <input type="hidden" name="permit.start" value="" />
        <input type="hidden" name="permit.end" value="" />
      {/if}
      {#if validType == "startendtime"}
        <input
          type="hidden"
          name="permit.duration.min"
          value={toISOString(calculatedDurationMinMS)}
        />
        <input
          type="hidden"
          name="permit.duration.max"
          value={toISOString(calculatedDurationMaxMS)}
        />
      {/if}
      <ul>
        <li>
          <label for="policy-valid-type">Type</label>
          <select
            id="policy-valid-type"
            value={validType}
            on:blur={(e) => changeValidType(e.target.value)}
            on:change={(e) => changeValidType(e.target.value)}
          >
            <option value="schedule">flexible schedule</option>
            <option value="startendtime">set times</option>
          </select>
        </li>
        {#if !validType || validType == "schedule"}
          <li>
            <label for="policy-valid-duration-min">Min hours</label>
            <select
              id="policy-valid-duration-min"
              name="permit.duration.min"
              value={toISOString(minDurationMS)}
              on:blur={(e) => (minDurationISO = e.target.value)}
              on:change={(e) => (minDurationISO = e.target.value)}
            >
              {#each times.filter((time) => time < 24 * hourMS) as i}
                <option value={toISOString(i)}>{formatTime(i)}</option>
              {/each}
            </select>
          </li>
          <li>
            <label for="policy-valid-duration-max">Max hours</label>
            <select
              id="policy-valid-duration-max"
              name="permit.duration.max"
              value={toISOString(maxDurationMS)}
            >
              {#each maxDurationValues as i}
                <option value={toISOString(i)}>{formatTime(i)}</option>
              {/each}
            </select>
          </li>
        {:else if validType == "startendtime"}
          <li>
            <label for="policy-valid-start-time">Start time</label>
            <select
              id="policy-valid-start-time"
              name="permit.start"
              bind:value={startTime}
            >
              {#each [...Array(24).keys()] as hr}
                {#each [0, 15, 30, 45] as min}
                  <option
                    value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                      ? '0' + min
                      : min}:00"
                    >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                    {hr >= 12 ? "PM" : "AM"}</option
                  >
                {/each}
              {/each}
            </select>
          </li>

          <li>
            <label for="policy-valid-end-time">End time</label>
            <select
              id="policy-valid-end-time"
              name="permit.end"
              bind:value={endTime}
            >
              {#each [...Array(24).keys()] as hr}
                {#each [0, 15, 30, 45] as min}
                  <option
                    value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                      ? '0' + min
                      : min}:00"
                    >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                    {hr >= 12 ? "PM" : "AM"}</option
                  >
                {/each}
              {/each}
            </select>
          </li>
          <li>
            <label for="policy-valid-duration-min">Min stay</label>
            <select id="policy-valid-duration-min" bind:value={minCount}>
              {#each minCountValues as i}
                <option value={i}>{i}</option>
              {/each}
            </select>
          </li>
          <li>
            <label for="policy-valid-duration-max">Max stay</label>
            <select id="policy-valid-duration-max" bind:value={maxCount}>
              {#each maxCountValues as i}
                <option value={i}>{i}</option>
              {/each}
            </select>
          </li>
        {/if}
        <li>
          <label for="policy-valid-future-max">In advance</label>
          <select
            id="policy-valid-future-max"
            name="permit.valid.minimum.future.maximum"
            value={editingPolicy.valid.min.future.max}
          >
            <option value="PT24H">up to 24 hours</option>
            <option value="PT{2 * 24}H">up to 48 hours</option>
            <option value="PT{3 * 24}H">up to 72 hours</option>
            <option value="PT{4 * 24}H">up to 4 days</option>
            <option value="PT{5 * 24}H">up to 5 days</option>
            <option value="PT{6 * 24}H">up to 6 days</option>
            <option value="PT{7 * 24}H">up to 7 days</option>
            <option value="PT{14 * 24}H">up to 14 days</option>
            <option value="PT{30 * 24}H">up to 30 days</option>
            <option value="PT{45 * 24}H">up to 45 days</option>
            <option value="PT{60 * 24}H">up to 60 days</option>
            {#if validType == "startendtime"}
              <option value="PT{90 * 24}H">up to 90 days</option>
              <option value="PT{180 * 24}H">up to 180 days</option>
              <option value="PT{365 * 24}H">up to 365 days</option>
            {/if}
            <!-- <option value="PT0H">Advance</option> -->
          </select>
        </li>
        <li>
          <label for="policy-valid-past-max">In progress</label>
          <select
            id="policy-valid-past-max"
            name="permit.valid.min.past"
            value={editingPolicy.valid.min.past}
          >
            <option value="">allow</option>
            <option value="PT0S">don't allow</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Agreement:</p>
      <ul>
        <li>
          <!-- <label for="policy-field-agreement">Agreement</label> -->
          <textarea
            id="policy-field-agreement"
            name="permit.agreement"
            value={get(editingPolicy, "agreement.text", "")}
          />
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
