<script>
    import { onMount } from "svelte";
    import { get, capitalize } from "lodash-es";
    import { format } from "date-fns";
    import { stringToDates } from "./util/datetime";
    import NoTenants from "./NoTenants.svelte";
    import AddUnits from "./AddUnits.svelte";
    import ResetTenant from "./ResetTenant.svelte";
    import { downloadHandler } from "./util/exportTable";
    import { fetchResetTenantCode } from "./api";
    

    export let property = null;
    export let tenants = null;
    let table = null;

    onMount(() => {
        table && table.scrollIntoView();
    });

    let add = null;

    let tenant = null;

</script>
{#if add}
    <AddUnits bind:property={add}/>
{/if}
{#if tenants}
<table class="tenants" data-count={get(tenants, "length", "")} bind:this={table}>
    
    <thead>
        <tr>
            <th scope="col">Unit</th>
            <th scope="col">Passcode</th>
            <th scope="col">Last Turned Over</th>
            <th scope="col"></th>
        </tr>
    </thead>
    {#if !!tenants && !!tenants.length}
    <tbody>
    {#each tenants as item}
        {#if item}
        <tr>
            <th scope="row"><data class="{item.type} id {[ item.format, item.size ].filter(i => !!i).join(" ")}" value="{item.id}">{item.display}</data></th>
            <td><span>{item.authcode || ""}</span></td>
            <td><time datetime="{item.valid.utc}">{format(stringToDates(item.valid.utc)[0], "EE MMM d yyyy h:mm a")}</td>
            <td><button type="button" disabled={tenant && tenant.id === item.id} on:click={e => (tenant = item)}>Edit</button></td>
        </tr>
        {/if}
    {/each}
    </tbody>
    {/if}
    <tfoot>
        <tr>
            <th colspan="4">
            {#if property}
            <button type="button" on:click={e => (add = property)}>Add {get(property, "tenants.title")}s</button><a href="#" download="{get(property, "name", "")} {get(property, "tenants.title", "")} Passcodes.csv" on:click={e => downloadHandler(e, table)}>Download Spreadsheet</a>
            {/if}
        </th>
        </tr>
    </tfoot>
    <!-- <caption>
        {#if tenants.length > 0}
            <a href="#" download="{get(property, "name", "")} {get(property, "tenants.title", "")} Passcodes.csv" on:click={downloadHandler}>Export</a>
        {/if}
    </caption> -->
</table>
{/if}
{#if tenant}
<ResetTenant bind:tenant={tenant} />
{/if}
<!-- {#if !!tenants && tenants.length === 0}
    <NoTenants />
{/if} -->