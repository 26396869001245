import { writable, derived, readable } from "svelte/store";
import { items } from "@parkingboss/svelte-utils";
import {
  debounce,
  merge,
  get,
  set,
  pick,
  map,
  has,
  each,
  find,
  every,
  difference,
  sortBy,
} from "lodash-es";
import { addMinutes, startOfDay, endOfDay, addDays, addMonths } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import {
  api,
  fetchAndStorePropertyPoliciesPermits,
  fetchAndStoreUnits,
  resolveProperty,
  fetchUserValidForProperty,
  fetchAndStoreUsers,
  fetchAndStorePermits,
  fetchPaymentMetrics,
  fetchUnits,
} from "./api";
import { client as mqtt } from "./mqtt";
import { online, smscreen, pagevisible } from "./util/behaviorstores";
import { param, params } from "./util/params";

//console.log("apiBase=", api.settings.apiBase);

const root = document && document.documentElement;

function setData(elem, key, value) {
  if (!elem || !key || !value) return;
  elem.setAttribute("data-" + key, value);
}

const comparer =
  !!window.Intl && !!window.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export { smscreen };

export const minuteNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 60 * 1000);
  return () => clearInterval(i);
});
export const secondNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 1000);
  return () => clearInterval(i);
});

export const timeStore = function (seconds) {
  return readable(new Date(), (set) => {
    set(new Date());
    const i = setInterval(() => set(new Date()), seconds * 1000);
    return () => clearInterval(i);
  });
};

online.subscribe((v) => console.log("online=", v));
smscreen.subscribe((v) => console.log("smscreen=", v));
pagevisible.subscribe((v) => console.log("pagevisible=", v));

// setup a store that only returns valid auth/user info
export const validAuth = derived(
  [api.user, minuteNow],
  ([$auth, $now], set) => {
    if (!$auth || !$auth.expires) return set(null);
    if (new Date($auth.expires) < addMinutes($now, 1)) return set(null); // expired or gonna expire in the next minute
    set($auth);
  }
);

export const userId = derived(validAuth, ($auth) => $auth && $auth.id);

export const state = items;

export const tenantId = param("tenant");
export const spaceId = param("space");

export { params };

function paramStore(selector, onlyUpdateForRealValues = false) {
  var value = null;
  return derived(params, (params, set) => {
    if (!params) return;
    var newValue = selector(params);
    if (!newValue && !value) return;
    if (!newValue && onlyUpdateForRealValues) return;
    //console.log("setting new paramstore value", newValue);
    if (newValue != value) set((value = newValue));
  });
}

export const policyId = derived(params, (params) => params && params.policy);
export const permitId = derived(params, (params) => params && params.permit);

permitId.subscribe(async (value) => {
  if (!value) return;

  // permit id changed
  await fetchAndStorePermits([value]);
});

// conditional derive
export const propertyId = derived(params, (params, set) => {
  if (!params || !params.property) return;
  set(params.property);
});

export const view = derived(params, (params) => params.view);
export const valid = derived(params, (params) => params && params.valid);
export const search = derived(
  params,
  (params) => params && (params.q || params.search)
);

export const token = derived(api.user, ($user) => $user.token);

//user.subscribe($user => console.log("user=", $user));

export const validDefaultNow = (function () {
  let value = null;
  return derived([valid, minuteNow], ([valid, now], set) => {
    if (!valid)
      return set((value = `${now.toISOString()}/${now.toISOString()}`));
    if (valid != value) return set((value = valid));
  });
})();

export const validDefaultToday = (function () {
  let value = null;
  return derived([valid, minuteNow], ([valid, now], set) => {
    if (!valid)
      return set(
        (value = `${format(now, "yyyy-MM-dd")}T00:00:00/${format(
          now,
          "yyyy-MM-dd"
        )}T23:59:59`)
      );
    if (valid != value) return set((value = valid));
  });
})();

// background update watcher
export const updated = writable({});
let subscribedPropertyId = null;
setData(root, "mqtt", "connecting");
const mqttConnection = mqtt(
  "AKIAIUPPRVWKBYHY4UWQ",
  "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8",
  {
    message: (json) => {
      console.log("mqtt.message=", json);
      //updated.set(json);
      updated.set({
        scope: subscribedPropertyId,
        type: Object.keys(json)[0],
        updated: json[Object.keys(json)[0]],
      });
    },
    connect: () => setData(root, "mqtt", "online"),
    reconnect: () => setData(root, "mqtt", "connecting"),
    disconnect: () => setData(root, "mqtt", "offline"),
    offline: () => setData(root, "mqtt", "offline"),
    close: () => setData(root, "mqtt", "offline"),
  }
);

propertyId.subscribe((propertyId) => {
  if (!propertyId) return;
  if (subscribedPropertyId == propertyId) return;

  // propertyId changed...

  // unsubscribe
  if (!!subscribedPropertyId)
    mqttConnection.unsubscribe(`locations/${subscribedPropertyId}`);

  // subscribe
  mqttConnection.subscribe(`locations/${(subscribedPropertyId = propertyId)}`);
});

updated.subscribe((value) => {
  if (!value || !value.scope) return;
  fetchAndStorePropertyPoliciesPermits(value.scope);
});

// refresh permits on property change

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
let permitsRefresher = null;
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async (value) => {
  //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

  if (!value) return; // don't do anything, but keep previous value cached - leave refreshers active

  if (value === previousPropertyId) return; // the assignment changed, but not the actual value;

  // we have a new ID
  if (!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler
  if (!!permitsRefresher) clearInterval(permitsRefresher); // stop the previous scheduler

  previousPropertyId = value;

  //console.log("propertyId changed=", value);

  fetchAndStorePropertyPoliciesPermits(value);
  fetchAndStoreUnits(value);

  permitsRefresher = setInterval(
    () => fetchAndStorePropertyPoliciesPermits(value),
    5 * 60 * 1000
  );
  propertyRefresher = setInterval(
    () => fetchAndStoreUnits(value),
    10 * 60 * 1000
  );

  // console.log("writing propertyId to list", value);
  // propertyIds.update(prev => merge(prev, {
  //     [value]: new Date().toISOString(),
  // }));
});

export const property = derived([propertyId, state], ([id, items]) =>
  resolveProperty(items[id], items)
);

export const tenantsByUnit = derived(
  [propertyId],
  async ([$propertyId], set) => {
    if (!$propertyId) return set(null);

    const json = await fetchUnits($propertyId, false, false, "*/*");

    return set(
      Object.values(json.tenants.items)
        .map((item) => json.items[item] || item)
        .reduce(
          (map, item) => {
            (map[item.subject]["tenants"] =
              map[item.subject]["tenants"] || []).push(item);

            return map;
          },
          Object.values(json.units.items).reduce((map, item) => {
            map[item] = {
              unit: json.items[item],
            };
            return map;
          }, {})
        )
    );
  }
);

export const user = derived(
  [api.user, propertyId],
  async ([$user, $propertyId], set) => {
    if (!$user || !$propertyId) return; // no stuff
    var json = await fetchUserValidForProperty($propertyId);
    var user = get(json, ["items", get(json, "users.item")]);
    if (!!user) user.system = get(json, "authorizations.system", false);
    // not a system admin, need to check if user of this location
    if (!!user && !!$propertyId && !user.system) {
      const auth = find(
        map(
          get(json, "authorizations.items", []),
          (val) => get(json, ["items", val]) || val
        ),
        {
          subject: get(json, "authorizations.scopes[0]"),
          principal: user.id,
        }
      );
      if (!auth) return set(null); // no property user -- return?

      // add check for is valid
      user.roles = auth.roles;
    }
    if (!!user && !!user.system)
      user.roles = {
        admin: true,
        system: true,
      };
    set(user);
  }
);

function metered(item, state) {
  if (!item) return;
  const value =
    get(state, ["metered", "for", item.id]) ||
    get(state, ["metered", "for", item.subject]);
  if (!value) return value;

  Object.values(value.meters.items || value.meters).map(function (meter) {
    // assuming subjects is a { k, v }
    // meter.subjects = Object.values(meter.subjects).reduce((result, subject) => {
    //     if(typeof subject != "string") return result;
    //     //console.log(subject, result);
    //     if(!state[subject]) return result;
    //     result[subject] = state[subject]?.title || subject;
    //     return result;
    // }, meter.subjects);
    if (meter.subjects)
      for (const [k, v] of Object.entries(meter.subjects)) {
        meter.subjects[k] = state[v]?.title || v;
      }
    if (meter.principals)
      for (const [k, v] of Object.entries(meter.principals)) {
        meter.principals[k] = state[v] || v;
      }
    return meter;
  });

  console.log("metered=", value);

  return value;
}

function hydratePolicy(item, state) {
  if (!item) return item;

  item.statistics =
    get(state, ["statistics", "for", item.id]) ||
    get(state, ["statistics", "for", item.subject]);
  item.pricing =
    get(state, ["pricing", "for", item.id]) ||
    get(state, ["pricing", "for", item.subject]);
  item.metered = metered(item, state);

  item.property = resolveProperty(item.location, state);

  return item;
}

export const policies = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!state["policies"]) return null;
  var policies = map(
    state["policies"],
    (version, policy) => state[policy] || state[version]
  );
  //console.log("policies=", policies);
  if (!policies.every((item) => !!item)) return null; // not done loading

  return policies
    .filter(
      (item) =>
        !!item && item.scope === property.id && item.amenity !== "parking"
    )
    .map((item) => hydratePolicy(item, state))
    .sort((a, b) => comparer(a.title, b.title));
});

export const policy = derived(
  [policyId, policies, state],
  ([id, policies, items]) => {
    var item =
      !!id &&
      (hydratePolicy(items[id], items) ||
        (!!policies &&
          policies.find((item) => item.id === id || item.subject === id)));
    return item;
    return merge(item, {
      property: resolveProperty(item.location, items),
    });
  }
);

// on any propertyId change update the users
propertyId.subscribe(
  ($propertyId) => $propertyId && fetchAndStoreUsers($propertyId)
);

export const authorized = derived([property, state], ([property, state]) => {
  if (!property) return null;

  if (!get(state, "authorizations.items")) return null;
  var items = map(state.authorizations.items, (item, id) =>
    item.id ? item : state[id] || state[item]
  );
  console.log("authorizations=", items);
  if (!items.every((item) => !!item)) return null; // not done loading

  return (
    items
      //.filter(item => !!get(item, "roles.admin", false))
      .map((item) => {
        item.user = state[item.principal];
        return item;
      })
      .sort((a, b) => comparer(a.user.display, b.user.display))
  );
});

authorized.subscribe(($authorized) => console.log("users=", $authorized));

export const permits = derived(
  [property, state, policies],
  ([property, items, policies]) => {
    if (!property) return null;

    if (!property) return null;
    if (!items["permits"]) return null;
    var values = map(items["permits"], (value, key) => items[key]);
    //console.log("permits=", values);
    if (!values.every((item) => !!item)) return null; // not done loading

    //if(!every(values, i => !!i)) return null;

    return values
      .filter((permit) => permit && permit.amenity !== "parking")
      .map((permit) =>
        !permit
          ? permit
          : merge(permit, {
              property: resolveProperty(
                items[permit.location] || permit.location,
                items
              ),
              address: items[permit.address] || permit.address,
              policy:
                items[permit.issued.policy] ||
                items[permit.issued.issuer] ||
                permit.issued.issuer,
              vehicle: items[permit.vehicle] || permit.vehicle,
              spaces: (permit.spaces || []).map((i) => items[i] || i),
              tenant: items[permit.tenant] || permit.tenant,
            })
      );

    // check for missing?

    return values;
  }
);

export const permit = derived([permitId, state], ([permitId, items]) => {
  if (!permitId) return null;

  if (!items["permits"]) return null;

  var permit = items[permitId];
  if (!permit) return null;

  return merge(permit, {
    property: resolveProperty(items[permit.location] || permit.location, items),
    address: items[permit.address] || permit.address,
    policy:
      items[permit.issued.policy] ||
      items[permit.issued.issuer] ||
      permit.issued.issuer,
    vehicle: items[permit.vehicle] || permit.vehicle,
    spaces: (permit.spaces || []).map((i) => items[i] || i),
    tenant: items[permit.tenant] || permit.tenant,
  });
});

export const units = derived([property, state], ([property, state]) => {
  if (!property) return null;
  return map(get(state, "units.items"), (value, key) => state[key])
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const tenants = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!get(state, "tenants.items")) return null;
  return map(get(state, "tenants.items"), (value, key) => state[key])
    .map((item) => {
      if (!item) return item;
      item.authcode = get(
        state,
        ["authcodes", "items", item.id],
        item.authcode
      );
      //console.log("tenant=", item);
      return item;
    })
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const spaces = derived([property, state], ([property, items]) => {
  if (!property) return null;
  return map(items["spaces"], (value, key) => items[key]).filter(
    (item) => !!item && item.scope === property.id
  );
});
export const space = derived([spaceId, state], ([id, items]) => items[id]);
export const tenant = derived([tenantId, state], ([id, items]) => items[id]);

// on permit update selected property
policy.subscribe((policy) => {
  if (!policy) return;
  params.update((prev) =>
    merge(prev, {
      property: policy.property.id || policy.property || policy.location,
    })
  );
});

let propertyTz = null;
export const propertyTimezone = derived(property, ($property, set) => {
  // only do if changed
  //if(!$property || !$property.timezone) set(property.timezone);
  if ($property && $property.timezone && $property.timezone != propertyTz) {
    set($property.timezone);
  }

  // no cleanup
});

export const propertyNow = derived(
  [propertyTimezone, minuteNow],
  ([$timezone, $now]) => {
    //console.log("propertyNow=", property,  property.timezone, now);

    if (!$timezone) return $now;
    return utcToZonedTime($now, $timezone);
  }
);

export const validDefaultPropertyToday = (function () {
  let value = null;
  return derived(
    [valid, propertyNow, propertyTimezone],
    ([$valid, $now, $tz], set) => {
      if (!$valid) {
        const min = startOfDay($now);
        const max = endOfDay($now);
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
      if ($valid != value) {
        var [min, max] = $valid.split("/").map((i) => utcToZonedTime(i, $tz));
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
    }
  );
})();

export const validDefaultPropertyWeek = (function () {
  let value = null;
  return derived(
    [valid, propertyNow, propertyTimezone],
    ([$valid, $now, $tz], set) => {
      if (!$valid) {
        const min = startOfDay(addDays($now, -6));
        const max = endOfDay($now);
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
      if ($valid != value) {
        var [min, max] = $valid.split("/").map((i) => utcToZonedTime(i, $tz));
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
    }
  );
})();

export const propertyPaymentMetrics = derived(
  propertyId,
  async ($propertyId, set) => {
    set(null);

    if (!$propertyId) return;

    const now = new Date();

    var json = await fetchPaymentMetrics(
      `${format(addMonths(now, -2), "yyyy-MM-01'T'00:00:00")}/${format(
        addDays(now, 1),
        "yyyy-MM-dd'T'00:00:00"
      )}`,
      {
        property: $propertyId,
        datetimes: ["P1D", "P1W", "P1M"],
        metrics: ["total", "policy", "property"],
        payments: ["total", "net"],
      }
    );

    //console.log("paymentmetrics=", json);

    set(json);
  }
);

// loggers
//permit.subscribe(value => console.log("permit.store=", value));
//properties.subscribe(value => console.log("properties.store=", value));
// permits.subscribe(value => console.log("permits.store=", value));
// view.subscribe(value => console.log("view.store=", value));
//policy.subscribe(value => console.log("policy.store=", value));
// policies.subscribe(value => console.log("policies.store=", value));
//units.subscribe(value => console.log("units.store=", value));
