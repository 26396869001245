import { map } from "lodash-es";

function tableToCsv(table) {
    return map((table || root).querySelectorAll('thead tr, tbody tr'), (row) => {
        return map(row.querySelectorAll("th,td"), (col) => {
            if(col.matches("thead th") && !col.textContent) return "";
            if(!!col.querySelector("button:only-child")) return "";
            return '"' + col.textContent.replace(/"/g, '""') + '"'; // escape double quotes
        }).join(",");
    }).join("\r\n");
}

const MIME = { type: 'text/csv;charset=utf-8' };

function exportTable(table, filename) {
    const csv = tableToCsv(table);
    return new Blob([csv], MIME);
    //saveAs(blob, filename);
}

export function downloadHandler(e, table) {

    //e.preventDefault();

    table = table || e.target.closest("table");
    var blob = exportTable(table);
    
    var url = URL.createObjectURL(blob);
    e.target.href = url;


    setTimeout(() => URL.revokeObjectURL(url), 30 * 1000)
}