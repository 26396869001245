<script>
    import { get } from "lodash-es";
    import { user } from "./stores";
    import Policy from "./Policy.svelte";
    import PolicyDuration from "./PolicyDuration.svelte";
    import NoPolicies from "./NoPolicies.svelte";
    import { metersFromPolicy } from "./util/policy";
import { interval } from "@parkingboss/api";
import { format, utcToZonedTime } from "date-fns-tz";

    export let policies = null;
</script>
{#if !!policies && !!policies.length}
<nav>
    <ul class="policies" data-count="{get(policies, "length", "")}">
        {#each policies as policy}
            <li>
                <header>
                    <!-- <a href="/properties/{policy.scope}/amenities/{policy.subject}"> -->
                        <Policy policy={policy} />
                    <!-- </a> -->
                </header>
                {#if get($user, "roles.admin", false)}
                    <a href="/properties/{policy.scope}/amenities/{policy.subject}/edit">
                        Edit
                    </a>
                {/if}
                {#if !!get(policy, "disabled", false)}
                    <p class="disabled">{get(policy, "disabled.reason")}</p>
                {:else}
                <ul>
                    {#if get(policy, "alert.text")}
                    <li>
                        <p>{policy.alert.text}</p>
                    </li>
                    {/if}
                    
                    {#if get(policy, "info.text")}
                    <li>
                        <p>{policy.info.text}</p>
                    </li>
                    {/if}
                    <li>
                        <PolicyDuration policy={policy} />
                    </li>
                    <li>
                        Unit {policy.tenant && policy.tenant.request ? policy.tenant.required ? "required" : "optional" : "n/a"}
                    </li>
                    <li>
                        Passcode {policy.authentication ? "required" : "n/a"}
                    </li>
                    <li>
                        Name {policy.name.request ? policy.name.required ? "required" : "optional" : "n/a"}
                    </li>
                    <li>
                        Phone {policy.tel.request ? policy.tel.required ? "required" : "optional" : "n/a"}
                    </li>
                    <li>
                        Email {policy.email.request ? policy.email.required ? "required" : "optional" : "n/a"}
                    </li>
                    {#each metersFromPolicy(policy) as meter}
                        <li>
                            {meter.display}
                            {#if meter.valid.interval.split('/').filter(i=>i).length == 2}
                            <time datetime="">{meter.valid.interval.split('/').map(i => format(utcToZonedTime(i, meter.timezone), "EEE MMM d yyyy h:mm a zzz", {
                                timeZone: meter.timezone
                            })).join(" - ")}</time>
                            {/if}
                        </li>
                    {:else}
                        <li>Unlimited</li>
                    {/each}
                </ul>
                {/if}
                
            </li>
        {/each}
        
    </ul>
</nav>
{:else if !!policies && policies.length === 0}
<NoPolicies />
{/if}