<script>

    export let policy;
    export let policies;

</script>
<form>
    <fieldset>
        <ul>
            <li>
                <label>Select</label>
                <ul>
                    {#if policies}
                    {#each policies as item}
                    <li><input id="select-policy-{item.id}" type="radio" name="action" value="{item.id}" checked={item.id == policy?.id} on:change={e => (e.target.checked && (policy = item))} /><label for="select-policy-{item.id}">{item.title}</label></li>
                    {/each}
                    {/if}
                </ul>
            </li>
        </ul>
    </fieldset>
</form>