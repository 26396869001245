<script>
    import { onMount } from "svelte";
    import { get, capitalize } from "lodash-es";
    import { fetchResetTenantCode, fetchResetUnit, fetchTransferTenant } from "./api";
    import { units } from "./stores";
    export let tenant = null;

    let element = null;
    onMount(() => {
        element && element.scrollIntoView();
    });

    let submittable = false;
    let submitting = false;

    let action = "code";

    let update = {
        "version": fetchResetUnit,
        "code": fetchResetTenantCode,
        "transfer":fetchTransferTenant
    };


    async function submit(e) {

        e.preventDefault();

        if(!tenant || submitting || !submittable) return;

        if(!window.confirm(`This is permanent and cannot be undone. Continue?`)) return;

        const form = e.target;
        const formData = new FormData(form); 

        submitting = true;

        const res = await update[action](tenant.scope, tenant, formData);

        //console.log("res=", res);
        submitting = false;

        if(res && res.message) return alert(res.message);

        submittable = false;
        tenant = null;

    }

    function change(e) {
        submittable = e.target.form && e.target.form.checkValidity();
    }

</script>
{#if tenant}
<form on:change={change} on:input={change} on:submit={submit} bind:this={element} on:reset={e => (tenant = null)}>
    <header>
        <h1>Edit {capitalize(tenant.format)}</h1>
    </header>
    <fieldset>
        <ul>
            <li>
                <label for="update-tenant-unit">{capitalize(tenant.format)}</label>
                <data class="{tenant.type} id {[ tenant.format, tenant.size ].filter(i => !!i).join(" ")}" value="{tenant.id}">{tenant.display}</data>
            </li>
            <li>
                <label for="update-tenant-action">Action</label>
                <ul on:change={e => {if(e.target.checked) action = e.target.value;}}>
                    <li><input id="update-tenant-code" type="radio" name="action" value="code" checked /><label for="update-tenant-code">change passcode</label></li>
                    <li><input id="update-tenant-version" type="radio" name="action" value="version" /><label for="update-tenant-version">turn over {tenant.format}</label></li>
                    <li><input id="update-tenant-transfer" type="radio" name="action" value="transfer" /><label for="update-tenant-transfer">transfer {tenant.format}</label></li>
                </ul>
                <!-- <select id="update-tenant-type" on:blur={e => (action = e.target.value)} on:change={e => (action = e.target.value)}>
                    <option value="code">generate new passcode</option>
                    <option value="version">turn over {tenant.format}</option>
                    <option value="transfer">transfer {tenant.format}</option>
                </select> -->
            </li>
            {#if "transfer" === action}
                <li>
                    <label for="update-tenant-transfer-to">Transfer to</label>
                    <select id="update-tenant-transfer-to" name="transfer">
                        {#each $units as unit}
                            <option value="{unit.id}">{unit.display}</option>
                        {/each}
                    </select> 
                </li>
                
            {/if}
            {#if "code" === action || "version" === action}
            <li>
                <label for="update-tenant-passcode">Passcode</label>
                <input id="update-tenant-passcode" type="number" name="password" placeholder="autogenerate" min="0" max="999999" minlength="4" maxlength="6">
            </li>
            {/if}
            <li>
                <label for="reset-tenant-notes">Notes</label>
                <textarea id="reset-tenant-notes" name="notes" placeholder="required" required></textarea>
            </li>
        </ul>
    </fieldset>
    {#if "reset" === action}
        <p>Important: turning over will archive the current history and create a clean {tenant.format} record. This is permanent and cannot be undone.</p>
    {/if}
    {#if "transfer" === action}
        <p>Important: turning over will archive the current history and create a clean {tenant.format} record. This is permanent and cannot be undone.</p>
    {/if}
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save"}</button>
        <button type="reset" disabled={submitting}>Cancel</button>
    </footer>
</form>
{/if}