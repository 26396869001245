<script>

    export let title;

</script>

<style>
    th {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: none;
        color: #3B3C41;
        padding-left: 16px !important;
        text-align: center;
    }

</style>

<th on:click id={$$props.id}>
    <p>{title}</p>
    <img alt="" />
</th>