<script>

    import { map, get } from "lodash-es";
    import { toInterval, stringToInterval, deviceTimeAsIfLocal } from "./util/datetime";
    import { areIntervalsOverlapping, format, isSameDay, addMilliseconds, isEqual } from "date-fns";
    import Usage from "./Usage.svelte";

    export let valid = null;
    export let policy = null;
    export let available = false;
    export let capacity = false;
    export let used = false;

    $: disabled = !!get(policy, "disabled.reason", false);

    $: validInterval = toInterval(valid);
    $: statistics = policy && policy.statistics && policy.statistics.intervals;
    $: console.log("policyusage=", statistics);
    $: usageData = validInterval && statistics && map(statistics, (value, interval) => {

        let [ usage, title ] = value.split("=");
        usage = usage.split("/").map(i => parseInt(i));
        return {
            interval,
            title,
            value,
            utc: stringToInterval(interval),
            local: stringToInterval(interval, deviceTimeAsIfLocal),
            usage,
            available: usage[1]-usage[0],
            full: (usage[1]-usage[0]) == 0,
            used: usage[0],
            capacity: usage[1],
            closed: usage[1] == 0,
            empty: usage[0] == 0,
        }
    }).find(item => areIntervalsOverlapping(validInterval, item.utc, {
        inclusive: validInterval.start == item.utc.start, // inclusive on start (allows for a zero-interval at beginning)
    }));

    // recast into  fake local time
    $: localInterval = usageData && usageData.local && {
        start: addMilliseconds(usageData.local.start, validInterval.start - usageData.utc.start),
        end: addMilliseconds(usageData.local.start, validInterval.end - usageData.utc.start),
    };
    $: issuePermits = !!get(policy, "issue.enabled");
    //$: console.log("localInterval=", localInterval);

</script>
{#if disabled}
    <p class="disabled">{get(policy, "disabled.reason")}</p>
{:else if usageData}
<h1>
    <time datetime="{usageData.interval}">
    {#if isEqual(localInterval.start, localInterval.end)}
        {format(localInterval.start, "h:mm a EE MMM d yyyy")}
    {:else}
        {#if isSameDay(localInterval.start, localInterval.end)}
        {format(localInterval.start, "h:mm a")} - {format(localInterval.end, "h:mm a EE MMM d yyyy")}
        {:else}
        {format(localInterval.start, "EE h:mm a")} - {format(localInterval.end, "h:mm a EE MMM d yyyy")}
        {/if}
    {/if}
    </time>
</h1>
<Usage title={usageData.title} available={(available || usageData.full) && issuePermits && !usageData.closed && usageData.available} capacity={(capacity || usageData.closed) && usageData.capacity} used={used && usageData.used} />
{/if}