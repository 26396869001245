<script>
    import { fetchDeletePolicyMeters } from "./api";
    import { get, filter } from "lodash-es";
    import { format } from "date-fns";
    import { utcToZonedTime } from "date-fns-tz";
    import { metersFromPolicy } from "./util/policy";

    export let policy = null;

    let submittable = false;
    let submitting = false;

    let remove = {};
    $: if(filter(remove).length > 0) submittable = true;

    function change(e) {
        submittable = filter(remove).length > 0;
    }

    async function submit(e) {

        e.preventDefault();

        if(!policy || submitting || !submittable) return;

        const form = e.target;

        const formData = new FormData(form);

        submitting = true;

        await fetchDeletePolicyMeters(policy, formData);

        submitting = submittable = false;
        remove = {};
    }


    $: items = policy && metersFromPolicy(policy).filter(item => {
            // must be concurrent, 0, no daytime valid, and closed ended validity
            // closed ended validity or no daytime are considered scheduled closes
            return item && item.format === "concurrent" && item.concurrent === 0 && !(get(item, "valid.daytime.length")) && !item.valid.interval.endsWith("/");
        });

        //$: console.log("meters=", items);

</script>
{#if policy}
    <form on:submit={submit} on:change={change}>
        <fieldset>
            <ul class="remove" data-count={get(items, "length", "")}>
                    {#each (items || []) as item}
                    <li>
                        <input type="checkbox" name="meter" value="{item.id}" checked={!!remove[item.id]} on:change={e => (remove[e.target.value] = e.target.checked)} />
                        <h1>Closed {#each item.valid.interval.split('/') as str}
                            <time datetime="{str}">{format(utcToZonedTime(str, policy.timezone), " EE MMM d yyyy h:mm a")}</time>
                            {/each}
                            <!-- {item.valid.interval.split('/').map(str => format(utcToZonedTime(str, policy.timezone), " EE MMM d yyyy h:mm a")).join(" - ")} -->
                        </h1>
                    </li>
                    {/each}
            </ul>
        </fieldset>
        {#if !!get(items, "length") && (submitting || submittable)}
        <footer>
            <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Remove Closures"}</button>
        </footer>
        {/if}
    </form>
{/if}