<script>
    import { format, utcToZonedTime } from "date-fns-tz";

    import { query } from "../util/router";
    
    import PermitValid from "./PermitValid.svelte";
    import UnitSelector from "../UnitSelector.svelte";
    import Agenda from "./Agenda.svelte";
    import PolicySelector from "./PolicySelector.svelte";
    import { policies, units, params } from "../stores";
    import { get } from "lodash-es";

    $: date = $params["date"];
    $: valid = $params["selected"];

    export let policy;
    export let valid;

    $: localValid = valid && policy && valid.split('/').map(i => utcToZonedTime(i, policy.timezone));

</script>
    <PolicySelector policies={$policies?.filter(item => item.issue && item.issue.enabled)} bind:policy={policy} />
{#if policy}
<section>
    <Agenda data={date} selected={valid} available={true} selectable={true} policy={policy} />
</section>
{/if}
{#if policy && valid}
<form>
    {#if policy}
        <input type="hidden" name="policy" value="{policy.id}" />
        <data class="policy" value="{policy.id}">{policy.title}</data>
    {:else}
        <select on:change={e => (e.target.value && query("policy", e.target.value))}>
            <option value="">---</option>
            {#if $policies}
            {#each $policies.filter(item => item.issue && item.issue.enabled) as policy}
            <option value="{policy.id}">{policy.title}</option>
            {/each}
            {/if}
        </select>
    {/if}
    {#if valid}
    <time datetime="{valid}">{format(localValid[0], "EEE h:mm a")} - {format(localValid[1], "EEE h:mm a")}</time>
    <input type="hidden" name="policy" value="{policy.id}" />
    {:else}
    <PermitValid policy={policy} bind:valid={valid} />
    {/if}
    {#if !!get(policy, "tenant.request", false)}
        <fieldset class="tenant">
            <ul>
            <li>
                <label>{get(policy, "tenant.title", "")}</label>
                <UnitSelector units={$units} />
            </li>
            </ul>
        </fieldset>
    {/if}

        {#if !!get(policy, "name.request", false)}
        <fieldset class="people">
            <p>Each person must have a pass—who is this pass for?</p>
            <ul>
            <li>
                <label for="permit-name">Name</label><input id="permit-name" type="text" name="name" value="" spellcheck="false" required={!!get(policy, "name.required", false)} autocomplete="name">
            </li>
            </ul>
        </fieldset>
        {:else if !!get(policy, "notes.request", false)}
            <fieldset class="people">
            <p>Who's going to be using this amenity?</p>
            <ul>
            <li>
                <label for="permit-notes">Names</label><input id="permit-notes" name="notes" spellcheck="false" placeholder={!!get(policy, "notes.required", false) ? "" : "optional"} required={!!get(policy, "notes.required", true)} />
            </li>
            </ul>
        </fieldset>
        {/if}
        
        {#if !!get(policy, "tel.request", false) || !!get(policy, "email.request", false)}
        <fieldset class="contact">
            <ul>
            {#if !!get(policy, "tel.request", false)}
            <li>
                <label for="permit-tel">Phone</label><input id="permit-tel" type="tel" name="tel" placeholder={!!get(policy, "tel.required", false) ? "required" : "optional"} required={!!get(policy, "tel.required", false)} value="" spellcheck="false" autocomplete="tel" />
            </li>
            {/if}
            {#if !!get(policy, "email.request", false)}
            <li>
                <label for="permit-email">Email</label><input id="permit-email" type="email" name="email" placeholder={!!get(policy, "email.required", false) ? "required" : "optional"} required={!!get(policy, "email.required", false)} value="" spellcheck="false" autocomplete="email">
            </li>
            {/if}
            </ul>
        </fieldset>
        {/if}
        
</form>
{/if}