<script>

    import { get } from "lodash-es";
    import { toMilliseconds, normalize } from "duration-fns";

    export let policy = null;

    const now = Date.now();

    $: issue = policy && !!get(policy, "issue.enabled");
    $: min = policy && (toMilliseconds(get(policy, "duration.min") || get(policy, "duration")));
    $: max = policy && (toMilliseconds(get(policy, "duration.max") || get(policy, "duration")));
    
    function formatTime(ms) {
        const parsed = normalize(ms);
        const hours = (parsed.days * 24) + parsed.hours;
        return `${hours}:${parsed.minutes.toString().padStart(2, "0")}`;
    }
</script>
{#if issue}
<p>
    {#if min == max}
         {formatTime(min)}
    {:else}
        {formatTime(min)} to {formatTime(max)}
    {/if}
    at a time
</p>
{/if}