<script>
    export let policy;

    $:url = policy && `https://display.amenitypass.app/properties/${policy.scope.id || policy.scope}/amenities/${policy.policy}`;
</script>
<figure class="tv">
    {#if policy}
    <iframe src="{url}"></iframe>
    {/if}
    <figcaption>
        <input type="text" readonly value="{url}" on:click={e => e.target.select()} />
    </figcaption>
</figure>