<script>
    import * as d3 from "d3";
    import ChartAxis from "./ChartAxis.svelte";
    import ChartLine from "./ChartLine.svelte";

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const margin = {
        top: 20,
        right: 0,
        bottom: 20,
        left: 0
    };
    const barWidth = 4;

    export let data;

    let width = 0;
    let height = 0;
    $: insetSize = {width: width - (margin.left + margin.right), height: height - (margin.top + margin.bottom)}
    $: xScale = d3.scaleBand()
                    .range([0, insetSize.width])
                    .domain(days);
    $: yMax = data && d3.max(Object.values(data).map(dayMap => Math.max(dayMap.current, dayMap.previous))) || 1;
    $: yScale = d3.scaleLinear()
                .range([insetSize.height, 0])
                .domain([0, yMax]);

</script>

<div class="chart-daily-usage" bind:clientWidth={width} bind:clientHeight={height}>
    <svg {width} {height}>
        {#if yMax > 0}
            <ChartLine text={yMax} x1={margin.left} x2={insetSize.width} textYOffset={-4} {margin} />
        {/if}
        <ChartAxis 
            scale={xScale} 
            position="bottom" 
            {height} 
            {margin}
            tickFormat={(day) => day[0].toLowerCase()}
        />
       {#if data}
        {#each Object.values(data) as day, index}
            <g transform={`translate(${margin.left + (xScale.bandwidth() * index)}, ${margin.top})`} >
                <rect 
                    class="previous"
                    x={(xScale.bandwidth()/2) - barWidth}
                    y={yScale(day.previous)} 
                    width={barWidth}
                    height={day.previous > 0 ? insetSize.height - yScale(day.previous) : 0}
                    rx={barWidth/2} />

                <rect
                    class="current" 
                    x={(xScale.bandwidth()/2)}
                    y={yScale(day.current)}
                    width={barWidth}
                    height={day.current > 0 ? insetSize.height - yScale(day.current) : 0}
                    rx={barWidth/2} />

            </g>
        {/each}
       {/if}
    </svg>
</div>