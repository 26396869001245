<script>

    import { fetchCreateTenants } from "./api";
    import { get } from "lodash-es";

    export let property = null;

    let submittable = false;
    let submitting = false;

    function change(e) {
        submittable = e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(!property || submitting || !submittable) return;

        const form = e.target;

        submitting = true;

        await fetchCreateTenants(property, form.elements["tenant"].value);

        submitting = submittable = false;

        property = null;

        form.reset();
    }
</script>
{#if property}
<form on:change={change} on:input={change} on:submit={submit} class="tenants" on:reset={e => (property = null)}>
    <label for="new-tenants">Important: enter one {get(property, "tenants.title", "").toLowerCase()} per line:</label>
    <textarea id="new-tenants" name="tenant" required />
    <p>Please note: {get(property, "tenants.title", "").toLowerCase()}s will be created once and duplicates will be skipped.</p>
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Add " + get(property, "tenants.title") + "s"}</button>
        <button type="reset" disabled={submitting}>Cancel</button>
    </footer>
</form>
{/if}