<script>
    import { onMount } from "svelte";
    import { writable } from "svelte/store";
    import page from "page";
    import { endOfMonth, endOfDay, startOfMonth, subDays, subMonths, subWeeks } from "date-fns";
    import { propertyId, policies, propertyNow, propertyTimezone } from "./stores";
    import Table from "./components/Table.svelte";
    import AmenityTableRow from "./components/metrics/AmenityTableRow.svelte";
    //import PolicyRevenueTableRow from "./components/metrics/PolicyRevenueTableRow.svelte";
    import {
        fetchAmenityMetrics
        //,fetchPaymentMetrics
    } from "./api";
    import { 
        lastWeek
    } from "./util/metrics";
    import { toZoneISOString } from "./util/datetime";
    import startOfWeek from "date-fns/startOfWeek";


    const amenityMetrics = writable(null);
    //const revenueMetrics = writable(null);

    $: parkingPolicies = $policies?.filter(policy => (policy.issue || policy.issue.enabled) && policy.format === "parking");
    $: otherPolicies = $policies?.filter(policy => (policy.issue || policy.issue.enabled) && policy.format !== "parking");

    $: parkingMetrics = $amenityMetrics?.metrics.items.filter((item) => {
        const isParking = $amenityMetrics.items?.[item.policy]?.amenity === "parking";
        return item.intervals === "datetimes" && item.datetimes === "P1D" && item.source === "permits" && isParking;
    }).reduce((acc, item) => {
        acc[item.policy] = item;
        return acc;
    }, {});

    $: otherMetrics = $amenityMetrics?.metrics.items.filter((item) => {
        const isParking = $amenityMetrics.items?.[item.policy]?.amenity === "parking";
        return item.intervals === "datetimes" && item.datetimes === "P1D" && item.source === "permits" && !isParking;
    }).reduce((acc, item) => {
        acc[item.policy] = item;
        return acc;
    }, {});;

    $: capacity = $amenityMetrics?.metrics.items.filter((item) => item.source === "capacity").reduce((acc, item) => {
        acc[item.policy] = item;
        return acc;
    }, {});

    const amenityTableColumns = [
        {
            column: "type",
            title: "Type"
        },
        {
            column: "active_now",
            title: "Active Now"
        },
        {
            column: "users_today",
            title: "Today"
        },
        {
            column: "users_week",
            title: "Last 7 Days"
        },
        {
            column: "users_daily",
            title: "Daily"
        },
    ];

    const paymentTableColumns = [
        {
            column: "type",
            title: "Type"
        },
        {
            column: "this_month",
            title: "This Month"
        },
        {
            column: "last_month",
            title: "Last Month"
        },
        {
            column: "daily",
            title: "Daily"
        }
    ];

    onMount(async () => {
        const amenityStart = startOfWeek(subWeeks($propertyNow, 1));
        const paymentStart = startOfMonth(subMonths($propertyNow, 1));
        const options = {
            property: $propertyId,
            datetimes: "P1D",
            capacity: true
        }
        const amenityInterval = `${toZoneISOString(amenityStart, $propertyTimezone)}/${toZoneISOString(endOfDay($propertyNow), $propertyTimezone)}`;
        const am = await fetchAmenityMetrics(amenityInterval, options);
        amenityMetrics.set(am);

        // const paymentsInterval = `${toZoneISOString(paymentStart, $propertyTimezone)}/${toZoneISOString($propertyNow, $propertyTimezone)}`;
        // const pm = await fetchPaymentMetrics(paymentsInterval, options);
        // revenueMetrics.set(pm);
    });

    const onRowClick = (policyId) => {
        page(`/properties/${$propertyId}/metrics/${policyId}?valid=${$lastWeek}`);
    };
</script>

<section id="metrics">
    <h1>Dashboard</h1>
    <!-- <div class="metrics-group">
        <h2>Net Revenue</h2>
        <Table id="payments" columns={paymentTableColumns}>
            <PolicyRevenueTableRow 
                data={$revenueMetrics?.metrics.items[0]} 
                title={"Net Revenue"} 
                on:click={() => onRowClick("revenue")} />
        </Table>
    </div> -->
    {#if parkingPolicies?.length > 0}
        <div class="metrics-group">
            <h2>Parking</h2>
            <Table id="parking" columns={amenityTableColumns}>
                {#each parkingPolicies as policy}
                    <AmenityTableRow 
                        policy={policy}
                        data={parkingMetrics?.[policy.policy]} 
                        capacity={capacity?.[policy.policy] || 0} 
                        on:click={() => onRowClick(policy.policy)} />
                {/each}
            </Table>
        </div>
    {/if}
    {#if otherPolicies?.length > 0}
        <div class="metrics-group">
            <h2>Amenities</h2>
            <Table id="amenities" columns={amenityTableColumns}>
                {#each otherPolicies as policy}
                    <AmenityTableRow 
                        policy={policy}
                        data={otherMetrics?.[policy.policy]} 
                        capacity={capacity?.[policy.policy] || 0} 
                        on:click={() => onRowClick(policy.policy)} />
                {/each}
            </Table>
        </div>
    {/if}
</section>