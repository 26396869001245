<script>
    import { writable } from "svelte/store";
    import { getYear } from "date-fns";
    import page from "page";
    import { utcToZonedTime } from "date-fns-tz";
    import BarChart from "./components/metrics/BarChart.svelte";
    import { BarChartType } from "./components/metrics/BarChartType.js";
    import { 
        policyId, 
        propertyId, 
        policies,
        validDefaultPropertyToday,
        propertyTimezone
    } from "./stores";
    import { 
        getTopStats,
        yearToDate,
        lastYear,
        lastMonth,
        lastWeek,
        getTimeOfDayAndDayOfWeek,
        getTopUsers, 
    } from "./util/metrics";
    import {
        fetchPaymentMetrics,
        fetchAmenityMetrics
    } from "./api.js";
    import TimeOfDayAndDayOfWeekChart from "./components/metrics/TimeOfDayAndDayOfWeekChart.svelte";
    import DayOfWeekChart from "./components/metrics/DayOfWeekChart.svelte";
    import DayChart from "./components/metrics/DayChart.svelte";
    import { utcToZoned } from "./util/datetime";

    const MetricsIntervals = {
        dayOfWeek: "PT24H",
        dayOfWeekTimeOfDay: "PT6H",
        timeOfDay: "PT1H"
    }

    const amenityMetrics = writable(null);
    const revenueMetrics = writable(null);

    let controller;
    let loading = false;
    let selectedRange = "LastWeek";
    
    $: isShowingRevenue = $policyId === "revenue";

    $: dateRanges = [
        {
            name: "YearToDate",
            title: `${utcToZonedTime($yearToDate.split("/")[0], $propertyTimezone).toLocaleString("default", {year: "numeric"})} YTD`,
            valid: $yearToDate
        },
        {
            name: "LastYear",
            title: getYear(utcToZonedTime($lastYear.split("/")[0], $propertyTimezone)),
            valid: $lastYear
        },
        {
            name: "LastMonth",
            title: `${utcToZonedTime($lastMonth.split("/")[0], $propertyTimezone).toLocaleString("default", {month: "long", year: "numeric"})}`,
            valid: $lastMonth
        },
        {
            name: "LastWeek",
            title: `${utcToZonedTime($lastWeek.split("/")[0], $propertyTimezone).toLocaleString("default", {month: "long"})} ${utcToZonedTime($lastWeek.split("/")[0], $propertyTimezone).getDate()}-${utcToZonedTime($lastWeek.split("/")[1], $propertyTimezone).getDate()}`,
            valid: $lastWeek
        }
    ];

    $: currentMetrics = isShowingRevenue ? $revenueMetrics : $amenityMetrics;

    $: dateRangeMetrics = currentMetrics?.metrics.items.filter(item => {
        return item.intervals === "datetimes" && item.datetimes == MetricsIntervals.dayOfWeek;
    })[0];
   
    $: dayOfWeekMetrics = currentMetrics?.metrics.items.filter(item => {
        return item.intervals === "daytimes" && item.daytimes === MetricsIntervals.dayOfWeek;
    })[0];
    
    $: timeOfDayAndDayOfWeekMetrics = currentMetrics?.metrics.items.filter(item => {
        return item.intervals === "daytimes" && item.daytimes === MetricsIntervals.dayOfWeekTimeOfDay;
    })[0];
    $: timeOfDayAndDayOfWeekChartData = timeOfDayAndDayOfWeekMetrics && getTimeOfDayAndDayOfWeek(timeOfDayAndDayOfWeekMetrics.values);

    $: timeOfDayMetrics = currentMetrics?.metrics.items.filter(item => {
        return item.intervals === "times" && item.times === MetricsIntervals.timeOfDay;
    })[0];

    $: topUsers = currentMetrics && getTopUsers(currentMetrics);

    $: topStats = dateRangeMetrics && getTopStats(dateRangeMetrics.values, $validDefaultPropertyToday);
    
    let menuItems;
    $: if ($policies) menuItems = [
        {
            title: "Net Revenue",
            options: [{title: "Net Revenue", value: "revenue"}]
        },
        {
            title: "Parking",
            options: $policies.filter(item => (item.issue || item.issue.enabled) && item.amenity === "parking")
                .map(item => {
                    return {title: item.title, value: item.policy}
                })
        },
        {
            title: "Amenities",
            options: $policies.filter(item => (item.issue || item.issue.enabled) && item.amenity !== "parking")
                .map(item => {
                    return {title: item.title, value: item.policy}
                })
        }
    ];

    $: if ($policyId && $validDefaultPropertyToday) fetchMetrics();

    const overviewBarChartType = () => {
        switch (selectedRange) {
            case "LastMonth":
                return BarChartType.month;
            case "LastYear":
            case "YearToDate":
                return BarChartType.year;
            default:
            return BarChartType.week;
        }
    };

    const topStatsTitle = () => {
        switch (selectedRange) {
            case "LastWeek":
            case "LastMonth":
                return "High day";
            case "LastYear":
            case "YearToDate":
                return "High month";
        }
    }

    const topStatsMonthOrDay = (d) => {
        const date = utcToZoned(new Date(d), $propertyTimezone);
        switch (selectedRange) {
            case "LastWeek":
            case "LastMonth":
                return date.toLocaleDateString("default", {weekday: "short"});
            case "LastYear":
            case "YearToDate":
                return date.toLocaleDateString("default", {month: "short"});
        }
    };

    const topStatsMonthOrYear = (d) => {
        const date = utcToZoned(new Date(d), $propertyTimezone);
        switch (selectedRange) {
            case "LastWeek":
            case "LastMonth":
                return date.toLocaleDateString("default", {month: "short", day: "numeric"});
            case "LastYear":
            case "YearToDate":
                return date.toLocaleDateString("default", {year: "numeric"});
        }
    };

    const barCharTitle = () => {
        switch (selectedRange) {
            case "LastYear":
            case "YearToDate":
                return "Monthly";
            default:  
                return "Daily";
        }
    };

    const fetchMetrics = async () => {
        loading = true;

        controller?.abort();
        controller = new AbortController();
        
        $amenityMetrics = null;
        $revenueMetrics = null;

        const options = {
            datetimes: MetricsIntervals.dayOfWeek,
            tenants: "count",
            daytimes: [MetricsIntervals.dayOfWeekTimeOfDay, MetricsIntervals.dayOfWeek],
            times: MetricsIntervals.timeOfDay,
            capacity: true,
            days: true,
            signal: controller.signal
        };
        if (isShowingRevenue) {
            options.property = $propertyId;
            try {
                const metrics = await fetchPaymentMetrics($validDefaultPropertyToday, options);
                // convert cents to dollars
                let items = metrics.metrics.items;
                if (items) {
                    items = items.map(item => {
                        item.values = Object.entries(item.values).reduce((acc, [key, value]) => {
                            acc[key] = value / 100;
                            return acc;
                        }, {});
                        return item;
                    });
                    metrics.metrics.items = items;
                }

                revenueMetrics.set(metrics);
            } catch (error) {
            }
        } else {
            options.policies = [$policyId];
            try {
                const metrics = await fetchAmenityMetrics($validDefaultPropertyToday, options);
                amenityMetrics.set(metrics);
            } catch (error) {
            }
        }
        
        loading = false;
    }

    const onPolicyChange = async event => {
        const policyId = event.target.value;
        page(`/properties/${$propertyId}/metrics/${policyId}?valid=${$validDefaultPropertyToday}`);
    };

</script>

{#if policies && $validDefaultPropertyToday}
    <section id="policy-metrics">
        <div class="menu">
            <select on:change={onPolicyChange} value={$policyId}>
                {#each menuItems as item}
                    {#if item.options.length > 0}
                        <optgroup label={item.title}>
                            {#each item.options as option}
                                <option value={option.value}>{option.title}</option>
                            {/each}
                        </optgroup>
                    {/if}
                {/each}
            </select>
        </div>
        <div class="date-range-options">
            {#each dateRanges as range}
                <a 
                    href={`/properties/${$propertyId}/metrics/${$policyId}?valid=${range.valid}`}
                    class="date-range" 
                    class:selected={$validDefaultPropertyToday === range.valid}
                    on:click={() => selectedRange = range.name}
                >
                    {range.title}    
                </a>
            {/each}
        </div>
        {#if !loading && currentMetrics}
            <div>
                <div class="total">
                    <h2>{isShowingRevenue ? "Total Net Revenue" : "Total Usage"}</h2>
                    <div>
                        <div class="overview">
                            <div class="chart">
                                <p class="section-title">{barCharTitle()}</p>
                                <BarChart 
                                    type={overviewBarChartType()} 
                                    data={dateRangeMetrics} 
                                    timezone={$propertyTimezone}
                                    margin={{top: 20, bottom: 20, left: 65, right: 0}} />
                            </div>
                            <div class="stats">
                                <div>
                                    <p class="title">Total</p>
                                    <p class="primary">{topStats?.total || '―'}</p>
                                </div>
                                <div>
                                    <p class="title">Average</p>
                                    <p class="primary">{topStats?.average || '―'}</p>
                                </div>
                                <div>
                                    <p class="title">{topStatsTitle()}</p>
                                    {#if topStats?.maxDate}
                                        <div>
                                            <p class="primary">{topStats.max}</p>
                                            <p>{`${topStatsMonthOrDay(topStats.maxDate)} ${topStatsMonthOrYear(topStats.maxDate)}`}</p>
                                        </div>
                                    {:else}
                                        <p class="primary">―</p>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <div class="top-users">
                            <p class="section-title">{`Top ${isShowingRevenue ? "Spenders" : "Users"}`}</p>
                            <table>
                                <tbody>
                                    {#if topUsers.length > 0}
                                        {#each topUsers as user}
                                            <tr>
                                                <td>{user.count}</td>
                                                <td>{user.display}</td>
                                            </tr>        
                                        {/each}
                                    {:else}
                                        <tr>
                                            <td>{'―'}</td>
                                            <td>{'―'}</td>
                                        </tr>  
                                    {/if}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="average">
                    <h2>Average Usage</h2>
                    <div>
                        <div class="day-of-week">
                            <p class="section-title">{"Day of week"}</p>
                            <DayOfWeekChart data={dayOfWeekMetrics} />
                        </div>
                        <div class="six-hour">
                            <p class="section-title">{"6 hour period"}</p>
                            <TimeOfDayAndDayOfWeekChart data={timeOfDayAndDayOfWeekChartData} />
                        </div>
                        <div class="hourly">
                            <p class="section-title">{"Hour"}</p>
                            <DayChart data={timeOfDayMetrics} />
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            <h1>Loading…</h1>
        {/if}
    </section>
{:else}
    <h1>Loading…</h1>
{/if}