<script>
   
    import Agenda from "./Agenda.svelte";
    import Policy from "./Policy.svelte";
    import PolicyUsage from "./PolicyUsage.svelte";
    import Permits from "./Permits.svelte";
    import { get } from "lodash-es";

    export let policy = null;
    export let permits = null;
    export let valid = null;

</script>
<section class="permits">
    <header>
        <Policy policy={policy} />
        <PolicyUsage valid={valid} policy={policy} capacity={true} used={true} />
    </header>
    <Permits permits={permits} valid={valid} policy={policy} />
</section>
<nav>
    {#if policy}
    <Agenda policy={policy} used={!!get(policy, "issue.enabled")} capacity={true} available={!!get(policy, "issue.enabled")} selected={valid} />
    {/if}
</nav>