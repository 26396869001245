import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
import { derived } from "svelte/store";
import { toMilliseconds, toString } from "duration-fns";
import { format, startOfDay, addDays } from "date-fns";

export function toZoneISOString(local, tz) {
    //if(!tz) return local.toISOString();
    return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
}

export function timeLocal(store, tz) {
    return derived(store, value => utcToZonedTime(value, tz));
}

export function utcToZoned(value, timezone) {
    return utcToZonedTime(value, timezone);
}

export function zonedInterval(interval, timezone) {
    if(!interval) return null;
    if(interval.split) {
        return interval.split("/").reduce((result, value, i) => {
            result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
            return result;
        }, {
            start:null,
            end:null,
            timezone
        });
    } else if(interval.start && interval.end) {
        if(interval.timezone != timezone) interval.timezone = timezone;
        return interval;
    } else if(Array.isArray(interval) && interval.length == 2) return interval.reduce((result, value, i) => {
        result[!!i ? "end" : "start"] = value && (value.toISOString ? value : utcToZonedTime(value, timezone));
        return result;
    }, {
        start:null,
        end:null,
        timezone
    });
    return null;
}

export function utcIntervalToZonedInterval(interval, timezone) {
    if(!interval) return null;
    if(interval.split) {
        return interval.split("/").reduce((result, value, i) => {
            result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
            return result;
        }, {
            start:null,
            end:null,
            timezone
        });
    } else if(interval.start && interval.end) {
        return {
            start: utcToZonedTime(interval.start, timezone),
            end: utcToZonedTime(interval.end, timezone),
            timezone
        };
    } else if(Array.isArray(interval) && interval.length == 2) return interval.reduce((result, value, i) => {
        result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
        return result;
    }, {
        start:null,
        end:null,
        timezone
    });
    return null;
}

export function durationMS(duration) {
    if(null == duration) return null;
    if(typeof(duration) == "string") return toMilliseconds(duration);
}

export function formatDuration(duration) {
    if(!duration) return duration;
    if(typeof(duration) == "string") return duration;
    return toString(duration);
}

export function utcToZonedDayInterval(datetime, timezone) {

    const zoned = utcToZonedTime(datetime, timezone);
    return {
        start:startOfDay(zoned),
        end:addDays(startOfDay(zoned), 1),
        timezone,
    }
}

export const display = function(date, relativeTo) {
    return format(date, "EE MMM d yyyy h:mm a");
}

export const iso8601Offset = function(date) {
    return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}
export const iso8601 = function(date) {
    if(!date) return "";
    return date.toISOString();
}

export const deviceTimeAsIfLocal = function(src) {

    var asDate = new Date(src);
    if(typeof src === "string") asDate = new Date(src.replace(/[+-][0-9]{1,2}:?[0-9]{2}$/, format(asDate, "xxx")));

    return asDate;

}

export const stringToInterval = function(interval, parser) {
    if(typeof(interval) !== "string") return interval;
    const [ start, end ] = interval.split("/").map(d => !!parser ? parser(d) : new Date(d));
    return { start, end };
}

export const stringToDates = function(interval, parser) {
    if(typeof(interval) !== "string") return interval;
    return interval.split("/").map(d => !!parser ? parser(d) : new Date(d));
}

function minMaxDate(input, f) {
    if(!input) return input;
    if(input.toISOString) return input;
    if(typeof input == "string") return new Date(f(...stringToDates(input)))
    return null;
}

export const maxDate = (input) => minMaxDate(input, Math.max);

export const minDate = (input) => minMaxDate(input, Math.min);

export const toInterval = function(value) {

    if(!value) return null;

    // single date
    if(value.toISOString) return {
        start:value,
        end:value,
    };

    // array of dates/num/string
    if(Array.isArray(value)) return {
        start: value[0] && new Date(value[0]),
        end: value[1] && new Date(value[1]),
    };

    return stringToInterval(value);

}