<script>

    export let property = null;

</script>

{#if property && property.id}
<data class="{property.type}" value="{property.id}">
    <figure>
        {#if property.photo}
        <img alt="property photo" src="{property.photo.url.replace("https://upload.parkingboss.com/files/", "https://parking-uploads.imgix.net/")}?auto=compress,format&fit=crop&h=250&w=500&crop=focalpoint"/>
        {/if}
    </figure>
    <h1>{property.name}</h1>
    <address>
        <span class="street">{property.address.street}</span>
        <span class="city">{property.address.city}</span>
        <span class="state">{property.address.state}</span>
        <span class="postal">{property.address.postal}</span>
    </address>
    {#if property.contact}
    <aside class="contact">
        {property.contact.name || "management office"}
        <address>
            {#if property.contact.email}
            <a href="mailto:{property.contact.email}">{property.contact.email}</a><br>
            {/if}
            {#if property.contact.tel}
            <a href="tel:+{property.contact.tel}">{property.contact.tel}</a>
            {/if}
        </address>
    </aside>
    {/if}  
</data>
{/if}