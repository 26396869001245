<script>
  import { token } from "./stores";
  export let property;

  $: parking = !!property?.parking.enabled;

  $: if (parking)
    location.href = `${property.manager.url}#access_token=${$token}`;

  function message(e) {
    if (!window.Intercom) return; // fall through

    Intercom("showNewMessage", "I'm interested in adding Parking Boss");

    e.preventDefault();
  }
</script>

{#if !parking}
  <aside class="upgrade parking">
    <h1>Hey there!</h1>
    <p>
      It looks like you’re not currently using <a
        href="https://www.luminousresidential.com/products/parkingboss?utm_source=amenitymanager-web&utm_campaign=amenitiesparking"
        target="_blank">Parking Boss</a
      > to manage guest and resident parking. Get in touch to find the best plan
      for your community.
    </p>
    <nav>
      <a
        href="https://www.luminousresidential.com/products/parkingboss?utm_source=amenitymanager-web&utm_campaign=amenitiesparking"
        target="_blank">Learn More</a
      >
      <a
        href="mailto:help@luminousresidential.com?subject=Parking Boss&body=Interested in adding Parking Boss"
        on:click={message}>I'm Interested…</a
      >
    </nav>
    <figure>
      <img src="/parking.png" />
    </figure>
  </aside>
{/if}
