<script>

    import { toMilliseconds } from "duration-fns";  
    import { fetchUpdatePolicy } from "./api";
    import { get, clone } from "lodash-es";

    

    export let policy = null;
    export let issue = true;
    export let disabled = false;

    let submittable = false;
    let submitting = false;

    // react only if in an unchanged state
    //$: editing = policy && !submittable ? clone(policy) : editing;
    let editing = null;

    // save the first one we get
    $: if(!!policy && !editing) editing  = clone(policy);
    //$: if(!!policy && (!editing || !submittable)) editing  = clone(policy);

    let selectedMinDurationISO = null;

    $: minDurationISO = selectedMinDurationISO || get(editing, "duration.min") || get(editing, "duration") || "PT30M";
    $: maxDurationISO = get(editing, "duration.max") || get(editing, "duration") || "PT1H";
    $: issue = !!get(editing, "issue.enabled");
    $: disabled = !!get(editing, "disabled");
    //$: unitType = get(editing, "tenant.format") || "unit";
    $: minDurationMS = toMilliseconds(minDurationISO);
    $: maxDurationMS = toMilliseconds(maxDurationISO);
    $: maxDurationValues = calculateMaxDuration(minDurationMS);
    $: newPhotoUrl = null;
    $: photoUrl = newPhotoUrl || (editing && editing.photo && (editing.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/") + "?auto=compress,format&w=500"));
    //$: console.log("editing=", editing);
    //$: console.log("issue=", issue, "disabled=", disabled);

    function change(e) {
        console.log("change", e);
        submittable = e.target.form && e.target.form.checkValidity();
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting || !submittable) return;

        const form = e.target;

        const formData = new FormData(form);

        submitting = true;

        // disable?

        
        await fetchUpdatePolicy(editing, formData);

        // clear the state
        submitting = submittable = false;
        editing = null;

        if(newPhotoUrl) {
            const oldPhotoUrl = newPhotoUrl;
            newPhotoUrl = null;
            URL.revokeObjectURL(oldPhotoUrl);
        }

    }

    function calculateMaxDuration(min) {
        const max = 24 * 60 * 60 * 1000;
        const items = []
        let i = min;

        // first 8 steps then on the hour
        for(i = min; i < max; i += min) {
            if(i <= 8 * min) items.push(i);
            else if(i % (60 * 60 * 1000) === 0) items.push(i);
        }

        return items;
    }
    
    function file(e) {
        const input = e.target;
        if(!input.files && !input.files[0]) return;
        const oldPhotoUrl = newPhotoUrl;
        newPhotoUrl = URL.createObjectURL(input.files[0]);
        if(oldPhotoUrl) URL.revokeObjectURL(oldPhotoUrl);
    }

    function issueDisabledChange(e) {

        if(e.target.checked === false) return; // only looking for target

        switch(e.target.value) {
            case "true":
                disabled = !(issue = true);
                break;
            case "false":
                disabled = issue = false;
                break;
            case "":
                disabled = editing.disabled = !(issue = false);
                break;
        }

    }

</script>
{#if editing}
<form on:change={change} on:input={change} on:submit={submit}>
    <header>
        <h1>Amenity Settings</h1>
    </header>

    <fieldset>
        <ul>
            <li>
                <figure>
                    <img class="policy {editing.title.toLowerCase().split(/[^a-z]+/gi).join(" ")}" alt="amenity" src="{photoUrl}" />
                </figure>
            </li>
            <li>
                <input id="policy-photo-new" type="file" title="Select photo" accept="image/*" name="photo" on:change={file} disabled={submitting} />
                <label for="policy-photo-new">Select photo</label>
            </li>
            
        </ul>
        
    </fieldset>

    <fieldset>
        <ul>
            <li>
                <label for="policy-title">Name</label>
                <input id="policy-title" name="title" type="text" required value="{get(editing, "title", "")}"/>
            </li>
            <!-- <li>
                <label for="policy-enabled">Visibility</label>
                <select id="policy-enabled" name="enabled" value={ editing.enabled+"" }>
                    <option value="true">visible</option>
                    <option value="false">hidden</option>
                </select>
            </li> -->
            <li>
                <label for="policy-permits">Status</label>
                <select id="policy-permits" name="permit.issue.enabled" value={ disabled ? "" : issue+""}  on:blur={issueDisabledChange} on:change={issueDisabledChange}>
                    <option value="true">pass required</option>
                    <option value="false">info only</option>
                    <option value="">closed 24/7</option>
                </select>
            </li>
        </ul>
    </fieldset>
        

    <!-- <fieldset>
        <ul class="toggle" on:change={issueDisabledChange}>
            <li><input id="policy-permits-true" type="radio" name="permit.issue.enabled" value="true" checked={!disabled && issue} /><label for="policy-permits-true">Require Pass</label></li>
            <li><input id="policy-permits-false" type="radio" name="permit.issue.enabled" value="false" checked={!disabled && !issue} /><label for="policy-permits-false">Info Only</label></li>
            <li><input id="policy-permits-disabled" type="radio" name="permit.issue.enabled" value="" checked={disabled} /><label for="policy-permits-disabled">Closed 24/7</label></li>
        </ul>
    </fieldset> -->
    

    {#if disabled}
        <fieldset class="closed">
            <p>Closed message:</p>
            <ul>
                <li>
                    <!-- <label for="policy-disabled">Message</label> -->
                    <textarea id="policy-disabled" name="disabled.reason" value="{get(editing, "disabled.reason", "")}" required placeholder="required"></textarea>
                </li>
            </ul>
        </fieldset>
        <!-- <p>Note: We'll restore your saved settings when you re-open this amenity.</p> -->
    {:else}
        <input type="hidden" name="issue.disabled.title" value="" />
    {/if}

    {#if !disabled}
        <fieldset>
            <p>Alert message:</p>
            <ul>
                <li>
                    <!-- <label for="policy-alert">Alert</label> -->
                    <textarea id="policy-alert" name="alert.text" value="{get(editing, "alert.text", "")}" placeholder="optional"></textarea>
                </li>
            </ul>
        </fieldset>
        <fieldset>
            <p>Info message:</p>
            <ul>
                <li>
                    <!-- <label for="policy-info">Info</label> -->
                    <textarea id="policy-info" name="info.text" value="{get(editing, "info.text", "")}" placeholder="optional"></textarea>
                </li>
            </ul>
        </fieldset>
    {/if}

    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save"}</button>
    </footer>
    
</form>
{/if}