import { endOfDay, endOfMonth, endOfWeek, endOfYear, isSameMonth, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears, format, areIntervalsOverlapping } from "date-fns";
import { derived } from "svelte/store";
import { propertyNow, propertyTimezone } from "../stores";
import { toZoneISOString, stringToInterval, toInterval } from "./datetime";
import { utcToZonedTime } from "date-fns-tz";

export const days = [
    "Sunday", 
    "Monday", 
    "Tuesday", 
    "Wednesday", 
    "Thursday", 
    "Friday", 
    "Saturday"
];
export const isoDayMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
};

export const yearToDate = derived([propertyNow, propertyTimezone], ([$propertyNow, $propertyTimeZone]) => {
    const min = startOfYear($propertyNow);
    const max = endOfDay(subDays($propertyNow, 1));
    const valid = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}`;
    return valid;
});

export const lastYear = derived([propertyNow, propertyTimezone], ([$propertyNow, $propertyTimeZone]) => {
    const lastYear = subYears($propertyNow, 1);
    const min = startOfYear(lastYear);
    const max = endOfYear(lastYear);
    const valid = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}`;
    return valid;
});

export const lastMonth = derived([propertyNow, propertyTimezone], ([$propertyNow, $propertyTimeZone]) => {
    const last = subMonths($propertyNow, 1);
    const min = startOfMonth(last);
    const max = endOfMonth(last);
    const valid = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}`;
    return valid;
});

export const lastWeek = derived([propertyNow, propertyTimezone], ([$propertyNow, $propertyTimeZone]) => {
    const last = subWeeks($propertyNow, 1);
    const min = startOfWeek(last);
    const max = endOfWeek(last);
    const valid = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $propertyTimeZone })}`;
    return valid;
});

export const getCurrentCount = (data, date, tz) => {
    const count = Object.entries(data).reduce((acc, [key, value]) => {
        const [start, end] = key.split("/");
        const startDate = utcToZonedTime(start, tz);
        const endDate = utcToZonedTime(end, tz);
        if (startDate <= date && date < endDate) {
            acc += value;
        }
        return acc;
    }, 0);
    return count;
};

export const getCountForDateRange = (data, validInterval, tz, tag) => {
    const count = Object.entries(data).reduce((acc, [key, value]) => {
        if (areIntervalsOverlapping(toInterval(validInterval), stringToInterval(key))) {
            return acc + value
        }
        return acc;
    }, 0);
    return count;
};

export const getRevenueForMonth = (data, date, tz) => {
    const payments = Object.entries(data).reduce((acc, [key, value]) => {
        const [start, _] = key.split("/");
        const startDate = utcToZonedTime(start, tz);
        if (isSameMonth(date, startDate)) {
            acc[key] = value / 100;
        }
        return acc;
    }, {});
    return payments;
};

export const getTopUsers = metrics => {
    const top = metrics.metrics.items.filter(item => item.group === "policy+tenant" && !!item.tenant)
                    .sort((a, b) => Object.values(b.values)[0] - Object.values(a.values)[0])
                    .slice(0, 10).reduce((acc, item) => {
                        const tenant = metrics.items[item.tenant];
                        if (tenant) {
                            const count = Object.values(item.values)[0];
                            const display = tenant.display;
                            acc.push({display, count})
                        }
                        return acc;
                    }, []);
    return top;
};

export const getTopStats = (data) => {
    let total = 0;
    let max = 0;
    let maxDate;

    const entries = Object.entries(data);
    for (const [key, value] of entries) {
        if (value >= max) {
            max = value;
            maxDate = key.split("/")[0];
        }
        total += value;
    }
    const average = Math.round((total / entries.length) * 100) / 100;
    const stats = {total, average, max, maxDate};
    return stats;
};

/**
 * groups incoming data into two buckets based on day of the week. ie:
 * {
 *     "Sunday": {
 *          current: 1,
 *          previous: 12
 *      }
 * }
 */
export const getDailyUsageChartData = (data, thisWeek, lastWeek, tz) => {
    const chartData = Object.entries(data).reduce((acc, [key, value]) =>{        
        const [start, end] = key.split("/");
        const startDate = utcToZonedTime(start, tz);
        const endDate = utcToZonedTime(end, tz);
        const day = startDate.toLocaleDateString(undefined, { weekday: 'long' });
        const dayMap = acc[day] || {current: 0, previous: 0};

        if (startDate >= thisWeek.start && endDate <= thisWeek.end) {
            dayMap.current += value;
        } else if (startDate >= lastWeek.start && endDate <= lastWeek.end) {
            dayMap.previous += value;
        }
        acc[day] = dayMap;
        return acc;
    }, {});
    return chartData;
};

/**
 * sorts iso incoming object based on the iso timestamps (ie 7T00:00:00)
 * and groups them into days of the week with their respective counts (ie {"Sunday": [0,2,4,5]})
 */
export const getTimeOfDayAndDayOfWeek = values => {
    const grouped = Object.entries(values).sort(([aKey,], [bKey,]) => {
        const aStart = aKey.split("/")[0];
        const bStart = bKey.split("/")[0];
        return aStart < bStart ? -1 : (aStart > bStart ? 1 : 0);
    }).reduce((acc, [key, value]) => {
        const [day, hour] = key.split("/")[0].split("T");
        const dayOfWeek = isoDayMap[day];
        const obj = acc[dayOfWeek] || {};
        obj[hour] = value;
        acc[dayOfWeek] = obj;
        return acc;
    }, {});
    return grouped;
};