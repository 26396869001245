<script>
    import { propertyId, policyId, property, propertyTimezone, policies, policy, spaces, view, valid, permits, minuteNow, tenants, propertyNow, validDefaultToday, validDefaultPropertyToday, authorized, user, smscreen, tenantId } from "./stores";
    import { fetchPermits, fetchUnitsSent } from "./api";
    
    import { merge, get, first } from "lodash-es";
    import { isFuture, isPast, isWithinInterval, format, isSameDay } from "date-fns";
    import PoliciesSummary from "./PoliciesSummary.svelte";
    import PoliciesLive from "./PoliciesLive.svelte";
    import Property from "./Property.svelte";
    import PolicyDetail from "./PolicyDetail.svelte";
    import PermitsReport from "./PermitsReport.svelte";
    import NewPolicy from "./NewPolicy.svelte";
    import EditPolicy from "./EditPolicy.svelte";
    import Users from "./Users.svelte";
    import EditProperty from "./EditPropertySettings.svelte";
    import Tenants from "./Tenants.svelte";
    import TenantsNotify from "./TenantsNotify.svelte";
    import Notices from "./Notices.svelte";
    import Metrics from "./Metrics.svelte";
    import PolicyMetrics from "./PolicyMetrics.svelte";
    import PoliciesDisplay from "./components/PoliciesDisplay.svelte";
    import NewPermit from "./components/NewPermit.svelte";
    import Parking from "./Parking.svelte";
    import Payments from "./components/Payments.svelte";
import NavTo from "./components/NavTo.svelte";

    //let status = null;
    //$: document.documentElement[status ? "setAttribute" : "removeAttribute"]("data-status", status);

    const defaultTitle = document.title;

    $: if($user && window.Intercom) window.Intercom("update", {
        email: $user.email,
        name: $user.name,
        user_id: $user.id,
        created_at: (new Date($user.created).getTime())/1000,
    });

    $: document.documentElement[$view ? "setAttribute" : "removeAttribute"]("data-view", $view);
    $: document.documentElement[$propertyId ? "setAttribute" : "removeAttribute"]("data-property", $propertyId);
    $: document.documentElement[$policyId ? "setAttribute" : "removeAttribute"]("data-policy", $policyId);
    //$: document.documentElement[$permitId ? "setAttribute" : "removeAttribute"]("data-permit", $permitId);

    $: {
        document.title = [ get($property, "name"), defaultTitle ].filter(item => !!item).join(" - ");
    }

    let canUseNotify = true; //$property && (new Date($property.created) > new Date(2020, 4, 26));

    $: if ($permits && $policies) {
        console.log("lengths", $permits.length, $policies.length)
    }

    function help(e) {

        if(!window.Intercom) return; // fall through

        Intercom("show");


        e.preventDefault();
    }
</script>

<header>
    {#if $property}
        <Property property={$property} />
    {:else}
        <h1>Loading…</h1>
    {/if}
</header>
<nav>
    {#if $property && $user && !$smscreen}
        <!-- {#if $user.roles.admin}
            <ul>
                <li><a href="/properties/{$propertyId}/launch" class:selected={ $view == "launch"}>Launch</a></li>
            </ul>
        {/if} -->
        <ul>
            <li><a href="/properties/{$propertyId}/live" class:selected={ $view == "live"}>Live <time datetime="{$propertyNow.toISOString()}">{format($propertyNow, "h:mm a")}</time></a></li>
            {#if $user.roles.admin}
            <li><a href="/properties/{$propertyId}/tenants" class="{get($property, "tenants.format", "unit")}" class:selected={$view == "tenants"}>Units</a></li>
            {/if}
            <li><a href="/properties/{$propertyId}/amenities" class:selected={ $view == "policies"}>Amenities</a></li>
            <li><a href="/properties/{$propertyId}/parking" class:selected={ $view == "parking"}>Parking</a></li>
            <li><NavTo updated="2021-08-24T12:00:00Z" url="/properties/{$propertyId}/payments" selected={$view == "payments"}>Payments</NavTo></li>
            <li><a href="/properties/{$propertyId}/metrics" class:selected={ $view == "metrics"}>Usage</a></li>
            <li><a href="/properties/{$propertyId}/permits" class:selected={ $view == "permits"}>Pass Log</a></li>
            
            <!-- <li><a href="/properties/{$propertyId}/reports" class:selected={ $view == "reports"}>Reports</a></li> -->
            <li><a href="/properties/{$propertyId}/preview" class:selected={ $view == "preview"}>App Preview</a></li>
            <!-- <li><a href="/properties/{$propertyId}/display" class:selected={ $view == "display"}>TV Display</a></li> -->
            <li><a href="/properties/{$propertyId}/notices" class:selected={ $view == "notices"}>Notices</a></li>
            {#if canUseNotify && $user.roles.admin}
            <li><a href="/properties/{$propertyId}/notify" class:selected={ $view == "notify"}>Notify</a></li>
            {/if}
            
            <!-- <li><a href="https://docs.amenityboss.com/properties/{$propertyId}" target="_blank">Notices</a></li> -->
            {#if $user.roles.admin}
            <li><a href="/properties/{$propertyId}/settings" class:selected={ $view == "settings"}>Settings</a></li>
            {/if}
            
            <!-- <li><a href="/properties/{$propertyId}/amenities/settings" class:selected={ $view == "policysettings"}>Settings</a></li> -->
        </ul>

            {#if $user.roles.admin}
            <ul>
                <!-- <li><a href="/properties/{$propertyId}/tenants/new" class:selected={ $view == "newtenant"}>Move In/Out</a></li> -->
                <li><a href="/properties/{$propertyId}/amenities/new" class:selected={ $view == "newpolicy"}>Add Amenity</a></li>
                <!-- <li><a href="/properties/{$propertyId}/settings">Settings</a></li> -->
            </ul>
        {/if}
    {/if}
</nav>
<main>
{#if $view == "policy"}
    <PolicyDetail policy={$policy} permits={$permits} valid={$valid || $minuteNow} />
{:else if $view == "editpolicy"}
    <EditPolicy property={$property} policy={$policy} />
{:else if $view == "preview"}
    <figure class="preview">
        <iframe title="mobile app" src="https://amenitypass.app/properties/{$propertyId}"></iframe>
    </figure>
{:else if $view == "newpermit"}
    <NewPermit policies={$policies || []} policy={$policy} valid={$valid} />
{:else if $view == "display"}
    <PoliciesDisplay policies={$policies} policy={$policy || ($policies && $policies[0])} />
{:else if $view == "newpolicy"}
    <NewPolicy property={$property} />
{:else if $view == "policies"}
    <PoliciesSummary policies={$policies} />
{:else if $view == "tenants"}
    <Tenants property={$property} tenants={$tenants} />
{:else if $view == "notify"}
    <TenantsNotify property={$property} tenants={$tenants} sent={fetchUnitsSent($propertyId)} />
{:else if $view == "metrics"}
    {#if $propertyTimezone }
        <Metrics />
    {:else}
        <h1>Loading…</h1>
    {/if}
{:else if $view == "policymetrics"}
    <PolicyMetrics />
{:else if $view == "notices"}
    <Notices property={$property} />
{:else if $view == "reports" || $view == "permits"}
    <PermitsReport permits={fetchPermits($propertyId, $validDefaultPropertyToday, { all: true, tenant: $tenantId, policy: $policyId })}/>
{:else if $view == "settings"}
    <EditProperty property={$property} />
    <section>
        <Users property={$property} authorized={$authorized} />
    </section>
{:else if $view == "parking"}
    <Parking property={$property} />
{:else if $view == "payments"}
    <Payments property={$property} />
{:else if $view == "debug"}
    <section>
        <ul>
            <li>Computer time = {new Date()} / {(new Date()).toISOString()}</li>
            <li>Property UTC time = {$propertyNow.toISOString()}</li>
            <li>Property local time = {format($propertyNow, "h:mm a")}</li>
            <li>$minuteNow = {$minuteNow} / {$minuteNow.toISOString()}</li>
            <li>Timezone = {get($property, "timezone")}</li>
        </ul>
    </section>
{:else if $view == "launch"}
    <aside class="help welcome launch">
        <header>
            <p>We're really excited that you're launching Amenity Boss! Before you get started, please take a look at the handy <a href="https://amenityboss.com/help" target="_blank">getting started walkthrough</a> we've put together.</p>
        </header>
        <ol>
            <!-- <li>
                <h1>Our Help Guide</h1>
                <p>We've put together a series of walkthroughs to help you through each of the next steps.</p>
                <ul>
                    <li><a href="https://amenityboss.com/help" target="_blank">Open Help Guide</a></li>
                </ul>
                
            </li> -->
            <li>
                <h1>The Basics</h1>
                <p>First, set your community photo, contact info, and {$property && $property.tenants.format}s. You can also invite any other users that need access.</p>
                <ul>
                    <a href="/properties/{$propertyId}/settings">Open Settings</a>
                    <a href="/properties/{$propertyId}/tenants">Add {$property && $property.tenants.title}s</a>
                    <!-- <a href="https://amenityboss.com/units" target="_blank">Upload a new unit list</a> -->
                </ul>
                
            </li>
            <li>
                <h1>Setup Amenities</h1>
                <p>Second, add and configure all the amenities you want to include.</p>
                <a href="/properties/{$propertyId}/amenities">Open Amenities</a>
                <a href="/properties/{$propertyId}/amenities/new">Add Amenity</a>
            </li>
            <li>
                <h1>Notify Your Residents</h1>
                <p>Finally, once you've added your amenities and everything is setup, it's time to let your residents know.</p>
                <a href="/properties/{$propertyId}/notices">Print Notices</a>
                {#if canUseNotify}
                    <a href="/properties/{$propertyId}/notify">Send Resident Notifications</a>
                {:else}
                    <a href="https://amenityboss.com/notify" target="_blank">Schedule Resident Notification</a>
                    <p><strong>Important: we cannot accept your resident directory via email and the notification process doesn't begin until Schedule Resident Notification above is completed.</strong></p>
                {/if}
                
            </li>
        </ol>
    <!-- <h1>OK, Let's Go!</h1>
    <p>Once you've setup your amenities and are ready to launch:</p>
    
    <ul>
        <li><a href="/properties/{$propertyId}/notices">Printable Notices</a></li>
    </ul>
    <p>If you have signed up for our resident notification service:</p>
    <ul>
        <li><a href="https://amenityboss.com/notify" target="_blank">Schedule Resident Notification</a></li>
    </ul> -->
</aside>
{:else}
    <header><h1>Live <time datetime="{$propertyNow.toISOString()}">{format($propertyNow, "h:mm a")}</time></h1></header>
    <PoliciesLive policy={$policy || ($smscreen && first($policies))} policies={$policies} permits={$permits} valid={$valid || $minuteNow} />
{/if}
</main>
<footer>
  <nav class="system">
    <ul>
      <li><a href="https://help.communityboss.app" on:click={help}>Get Help</a></li>
      <!-- <li><a href="https://status.parkingboss.com">System Status</a></li>
      <li><a href="https://updates.parkingboss.com">Updates</a></li> -->
      <li><a href="https://my.parkingboss.com/user/logout">Log Out</a></li>
    </ul>
  </nav>
</footer>