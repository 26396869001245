<script>
    import { propertyId } from "./stores";
</script>
<aside class="help policies empty welcome">
    <h1>Welcome!</h1>
    <p>We're really excited that you're launching Amenity Boss! </p>
    <ul>
        <li><a href="/properties/{$propertyId}/amenities/new">Add your first amenity</a></li>
        <li><a href="https://amenityboss.com/help" target="_blank">Review our getting started guide</a></li>
    </ul>

</aside>