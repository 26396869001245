<script>

import { sortBy } from "../util/sort";
import { tenantsByUnit, tenants } from "../stores";
import { format, parseISO } from "date-fns";

export let id = null;
export let value;
export let latest = true;
export let empty = "all";

$:console.log("tenant value=", value);

</script>
<select id={id} value="{value||""}" on:change={e => (value = e.target.value)}>
    <option value="">{empty}</option>
    {#if latest}
        {#each ($tenants||[]) as item}
        <option value="{item.id}">{item.display.toUpperCase()}</option>
        {/each}
    {:else}
    {#each sortBy(Object.values($tenantsByUnit || {}), "unit.display") as item}
        <optgroup label="{item.unit.display.toUpperCase()}">
            {#each sortBy(item.tenants, "valid.utc").reverse() as item}
            <option value="{item.id}">{item.valid.utc.split("/").reduce((str, date, i, list) => {
                if(str) str += " - ";
                if("" == date) str += "current";
                else str += format(parseISO(date), "MMM d yyyy");
                return str;
            }, "")}</option>
            {/each}
        </optgroup>
    {/each}
    {/if}
</select>