import { api, auth } from "./util/auth";
import { updateItems, items } from "@parkingboss/svelte-utils";
import {
  debounce,
  merge,
  has,
  set,
  each,
  map,
  get,
  orderBy,
  uniq,
  invokeMap,
  split,
  property,
} from "lodash-es";
import { addHours, addMinutes } from "date-fns";
import { toZoneISOString } from "./util/datetime";

export { api };

let authHeader = "";
api.user.subscribe(
  (auth) => auth && (authHeader = `Authorization=${auth.type} ${auth.token}`)
);

export function base() {
  return api.settings.apiBase;
}

function updateFor(key, value) {
  if (!key || !value) return;

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};
    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    return state;
  });
}

export function viewpoint(offset) {
  return encodeURIComponent(
    toZoneISOString(new Date().getTime() + (offset || 0))
  );
}

function formDataToURLSearchParams(formData) {
  const searchParams = new URLSearchParams();

  /**
   * This is all to replace this single line:
   * new URLSearchParams(formData), because Edge
   */
  const keys = [...formData.keys()];
  keys.forEach((key) => {
    /**
     * For 'checkboxes', we need to append the values to the search params
     * and not just add a comma separated string.
     */
    if (keys.filter((x) => x === key).length > 1) {
      /**
       * We grab all the values and append them in one go
       */
      formData.getAll(key).forEach((value) => {
        searchParams.append(key, value);
      });

      /**
       * Then remove all the remaining instances of the key from the
       * keys array we're looping around
       */
      keys.forEach((k, i) => {
        if (k === key) {
          keys.splice(i, 1);
        }
      });
    } else {
      // Strings are simple in comparison
      searchParams.set(key, formData.get(key));
    }
  });

  return searchParams;
}

// export const fetchPermits = async function (property, valid) {
//     const json = await Promise.all([
//             fetch(`${api.settings.apiBase}/properties/${property}/permits?viewpoint=${new Date().toISOString()}&vehicle=false&valid=${valid}&${authHeader}`),
//         ])
//         .then(values => Promise.all(values.map(res => res.json())))
//         //.then(values => (values.map(json => pick(json, "items"))))
//         .then(values => merge({}, ...values));

//     json.items["contacts"] = json.contacts;
//     json.items["notes"] = json.notes;
//     json.permits.items = orderBy(resolvePermits(json.permits.items, json.items).filter(item => item && item.policy && item.policy.amenity !== "parking"), [
//         "policy.title", "title", "valid.min.utc"
//     ], ["asc", "asc", "asc"]);
//     console.log(json.permits.items);
//     return json.permits;

// };

export async function fetchPermits(property, valid, options) {
  const qs = new URLSearchParams();

  if (options)
    for (const [k, v] of Object.entries(options)) {
      if (null != v) qs.set(k, v);
      else qs.delete(k);
    }
  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/permits?scope=${property}&viewpoint=${viewpoint()}&vehicle=false&valid=${encodeURIComponent(
        valid
      )}&${authHeader}&files=false&sent=false&payments=true&${qs.toString()}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  json.items["contacts"] = json.contacts;
  //json.items["notes"] = json.notes;
  json.items.attachments = json.attachments;
  json.items.fees = json.fees;

  json.permits.items = orderBy(
    resolvePermits(json.permits.items, json.items).filter(
      (item) =>
        item &&
        item.policy &&
        item.policy.amenity !== "parking" &&
        !item.continuous
    ),
    ["policy.title", "title", "valid.min.utc"],
    ["asc", "asc", "asc"]
  );
  //console.log(json.permits.items);
  return json.permits;
}

export async function fetchUnits(
  scope,
  sent = false,
  authcodes = true,
  valid = null
) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${api.settings.apiBase}/units/tenants?scope=${scope}&sent=${
        sent === true
      }&authcode=${
        authcodes === true
      }&viewpoint=${new Date().toISOString()}&valid=${encodeURIComponent(
        valid || new Date().toISOString() + "/" + new Date().toISOString()
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnitsSent(scope) {
  if (!scope) return null;
  const json = await fetchUnits(scope, true);

  return {
    items: json.items,
    sent: json.sent,
  };
}

export async function fetchPaymentMetrics(interval, options) {
  let url = `${base()}/payments/collected/metrics?viewpoint=${viewpoint()}&interval=${interval}&${authHeader}`;
  if (options.property) {
    url += `&scope=${options.property}`;
  }
  if (options.policies) {
    for (const policy of options.policies) {
      url += `&policy=${policy}`;
    }
  }
  if (options.payments && typeof options.payments == "string") {
    url += `&payments=${options.payments}`;
  } else if (options.payments && Array.isArray(options.payments)) {
    for (const payments of options.payments) {
      url += `&payments=${payments}`;
    }
  }
  if (options.metrics && typeof options.metrics == "string") {
    url += `&metric=${options.metrics}`;
  } else if (options.metrics && Array.isArray(options.metrics)) {
    for (const metric of options.metrics) {
      url += `&metric=${metric}`;
    }
  }
  if (options.times) {
    url += `&times=${options.times}`;
  }
  if (options.datetimes && typeof options.datetimes == "string") {
    url += `&datetimes=${options.datetimes}`;
  } else if (options.datetimes && Array.isArray(options.datetimes)) {
    for (const datetime of options.datetimes) {
      url += `&datetimes=${datetime}`;
    }
  }
  if (options.daytimes && typeof options.daytimes == "string") {
    url += `&daytimes=${options.daytimes}`;
  } else if (options.daytimes && Array.isArray(options.daytimes)) {
    for (const daytime of options.daytimes) {
      url += `&daytimes=${daytime}`;
    }
  }
  if (options.days) {
    url += `&days`;
  }
  if (options.tenants) {
    url += `&tenants=${options.tenants}`;
  }
  if (options.count) {
    url += `&metric=count&count=${options.count}`;
  }
  console.log(`PAYMENT METRICS URL = `, url);
  const response = await fetch(url, { signal: options.signal });
  const data = await response.json();
  return data;
}

export async function fetchViolationMetrics(interval, options) {
  let url = `${api.settings.apiBase}/violations/issued/metrics?viewpoint=${
    options.viewpoint || viewpoint()
  }&interval=${interval}&${authHeader}`;
  if (options.property) {
    url += `&scope=${options.property}`;
  }
  if (options.policies) {
    for (const policy of options.policies) {
      url += `&policy=${policy}`;
    }
  }
  if (options.times) {
    url += `&times=${options.times}`;
  }
  if (options.datetimes && typeof options.datetimes == "string") {
    url += `&datetimes=${options.datetimes}`;
  } else if (options.datetimes && Array.isArray(options.datetimes)) {
    for (const datetime of options.datetimes) {
      url += `&datetimes=${datetime}`;
    }
  }
  if (options.daytimes) {
    url += `&daytimes=${options.daytimes}`;
  }
  if (options.days) {
    url += `&days`;
  }
  console.log(`REPORTS URL ${url}`);
  const response = await fetch(url);
  const data = await response.json();
  return data;
}

export async function fetchAmenityMetrics(interval, options) {
  try {
    let url = `${
      api.settings.apiBase
    }/permits/policies/issue/metrics?&viewpoint=${viewpoint()}&public=true&interval=${interval}`;
    if (options.property) {
      url += `&scope=${options.property}`;
    }
    if (options.policies) {
      for (const policy of options.policies) {
        url += `&policy=${policy}`;
      }
    }
    if (options.times) {
      url += `&times=${options.times}`;
    }
    if (options.datetimes && typeof options.datetimes == "string") {
      url += `&datetimes=${options.datetimes}`;
    } else if (options.datetimes && Array.isArray(options.datetimes)) {
      for (const datetime of options.datetimes) {
        url += `&datetimes=${datetime}`;
      }
    }
    if (options.daytimes && typeof options.daytimes == "string") {
      url += `&daytimes=${options.daytimes}`;
    } else if (options.daytimes && Array.isArray(options.daytimes)) {
      for (const daytime of options.daytimes) {
        url += `&daytimes=${daytime}`;
      }
    }
    if (options.capacity) {
      url += `&capacity`;
    }
    if (options.days) {
      url += `&metrics=days&days`;
    }
    if (options.tenants) {
      url += `&metric=tenants&tenants=${options.tenants}`;
    }
    if (options.count) {
      url += `&metric=count&count=${options.count}`;
    }
    console.log(`AMENITY METRICS URL ${url}`);
    const response = await fetch(url, { signal: options.signal });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export const fetchAndStorePropertyPoliciesPermits = debounce(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${
          api.settings.apiBase
        }/properties?viewpoint=${new Date().toISOString()}&property=${property}`
      ),
      //fetch(`${api.settings.apiBase}/permits/policies/issue?scope=${property}&viewpoint=${new Date().toISOString()}&valid=${addHours(new Date(), -2).toISOString()}/&public=true&admin=true&disabled=true`),
      fetch(
        `${
          api.settings.apiBase
        }/permits/policies/issue/statistics?scope=${property}&viewpoint=${new Date().toISOString()}&valid=${addHours(
          new Date(),
          -2
        ).toISOString()}/&public=true&admin=false&disabled=false&actual=false`
      ),
      fetch(
        `${
          api.settings.apiBase
        }/permits?viewpoint=${new Date().toISOString()}&vehicle=false&scope=${property}&valid=${addHours(
          new Date(),
          -2
        ).toISOString()}/&${authHeader}`
      ),
      //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${new Date().toISOString()}`)
    ])
      .then((values) => Promise.all(values.map((res) => res.json())))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    processPolicyStatistics(json);

    each(json, (value, key) => {
      //console.log(key, value);
      if (has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);

    for (const [key, value] of Object.entries(json)) {
      if (!!value["for"]) updateFor(key, value);
    }
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchAndStorePermits(ids) {
  if (!ids) return {};
  if (typeof ids === "string") ids = [ids];
  if (!ids || !ids.length) return {};
  const res = await fetch(
    `${api.settings.apiBase}/permits?viewpoint=${new Date().toISOString()}${ids
      .map((id) => "&permit=" + id)
      .join("")}`
  );
  const json = await res.json();
  updateItems(json);
  return json;
}

export async function fetchAndStoreUnits(scope) {
  const json = await fetchUnits(scope);

  each(json, (value, key) => {
    //console.log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUserValidForProperty(property) {
  console.log("fetchUser", property);

  var res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?principal=current&scope=${property}&viewpoint=${new Date().toISOString()}&valid=${addMinutes(
      new Date(),
      5
    ).toISOString()}/&${authHeader}`
  );

  if (res.ok) {
    const json = await responseJson(res);

    return json;
  }

  return await responseJson(res);
}

export async function fetchAndStoreUsers(scope) {
  if (!scope) return {};

  var res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?scope=${scope}&viewpoint=${new Date().toISOString()}&valid=${addMinutes(
      new Date(),
      5
    ).toISOString()}/&${authHeader}`
  );

  if (res.ok) {
    const json = await responseJson(res);

    storeUsers(json);

    return json;
  }

  return await responseJson(res);
}

function storeUsers(json) {
  json.items["authorizations"] = json.authorizations;

  updateItems(json);
  return json;
}

export async function fetchAndStorePolicy(policy) {
  const json = await Promise.all([
    //fetch(`${api.settings.apiBase}/permits/policies/issue?id=${policy}&viewpoint=${new Date().toISOString()}&valid=${addHours(new Date(), -2).toISOString()}/&public=true&admin=true&disabled=true`),
    fetch(
      `${
        api.settings.apiBase
      }/permits/policies/issue/statistics?id=${policy}&viewpoint=${new Date().toISOString()}&valid=${addHours(
        new Date(),
        -2
      ).toISOString()}/&public=true&admin=true&disabled=true&actual=false`
    ),
    //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${new Date().toISOString()}`)
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  processPolicyStatistics(json);

  // each(json, (value, key) => {
  //     //console.log(key, value);
  //     if(has(value, "items")) json.items[key] = value.items;
  // });

  updateItems(json);

  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }

  return json;
}

export async function fetchUpdatePolicy(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${policy.scope}/permits/issuers/${
      policy.policy
    }?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    await fetchAndStorePolicy(policy.policy);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePermit(permit, formData) {
  if (permit.id) permit = permit.id;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${permit}?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    //const json = await responseJson(res);

    //await fetchAndStorePermits([ permit ]); // refetch
    //return json;
    //return {};
    const json = await responseJson(res);
    updateItems(json);
    return json;
  }

  return await responseJson(res);
}

export async function fetchDeletePermit(permit, formData) {
  if (permit.id) permit = permit.id;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${permit}?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);
    updateItems(json);
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePolicyMeters(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/meters?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    await fetchAndStorePolicy(policy.policy);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePolicyMetersConcurrent(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/meters/concurrent?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    await fetchAndStorePolicy(policy.policy);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePolicyClosed(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/closed?viewpoint=${new Date().toISOString()}&schedule=true&scheduled=false&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    await fetchAndStorePolicy(policy.policy);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePolicyValidIntervalMax(policy) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      policy.scope
    }/permits/policies/issue/${
      policy.policy
    }?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "DELETE",
      //body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    await fetchAndStorePolicy(policy.policy);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchCreatePolicy(scope, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${
      scope.id || scope
    }/permits/issuers?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await res.json();

    var created = json.items[json.policies.item];

    // each(pick(json, [ "policies" ]), (value, key) => {
    //     //console.log(key, value);
    //     if(has(value, "items")) json.items[key] = value.items;
    // });

    updateItems(json); // old style

    //await fetchAndStorePolicy(created.policy);

    //await fetchAndStorePropertyPolicies(scope.id || scope); // refetch
    //await fetchAndStorePolicy(created.policy);
    //return json;
    return created;
  }

  return await responseJson(res);
}

async function fetchAndStoreProperties(ids) {
  if (!ids || !ids.length) return {};
  var url =
    typeof ids === "string"
      ? `${
          api.settings.apiBase
        }/properties?viewpoint=${new Date().toISOString()}&property=${ids}`
      : `${
          api.settings.apiBase
        }/properties?viewpoint=${new Date().toISOString()}${ids
          .map((id) => "&property=" + id)
          .join("")}`;
  const res = await fetch(url);
  const json = await res.json();

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  updateItems(json);

  return map(get(json, "properties.items"), (id) =>
    resolveProperty(json.items[id], json.items)
  ); // expand the property first
}

export async function fetchUpdateProperty(property, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      property.id
    }?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    await fetchAndStoreProperties(property.id);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchCreateUser(scope, email, role) {
  scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/locations/${scope}/users?viewpoint=${new Date().toISOString()}&email=${encodeURIComponent(
      email
    )}&type=${role}&${authHeader}`,
    {
      method: "POST",
      //body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

export async function fetchDeleteUser(scope, formData) {
  scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?viewpoint=${new Date().toISOString()}&scope=${scope}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //storeUsers(json);

    //return json;
    //return json;
    return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

export async function fetchCreateTenants(scope, tenant) {
  scope = scope.id || scope;

  const lines = uniq(invokeMap(split(tenant, /\r?\n/), String.prototype.trim));
  console.log(lines);

  for (let i = 0; i < lines.length; i++) {
    var data = new FormData();
    data.append("tenant", lines[i]);
    let res = await fetch(
      `${
        api.settings.apiBase
      }/locations/${scope}/tenants?viewpoint=${new Date().toISOString()}&${authHeader}`,
      {
        method: "POST",
        body: data,
      }
    );
  }

  return await fetchAndStoreUnits(scope);
}

export async function fetchResetTenantCode(scope, tenant, formData) {
  scope = scope.id || scope;
  tenant = tenant.id || tenant;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/${tenant}/code?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchTransferTenant(scope, tenant, formData) {
  scope = scope.id || scope;
  tenant = tenant.id || tenant;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/${tenant}/subject?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchResetUnit(scope, tenant, formData) {
  scope = scope.id || scope;
  var subject = tenant.subject || tenant;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/units/${subject}/tenants?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchCreateSend(data) {
  let res = await fetch(
    `${
      api.settings.apiBase
    }/send?viewpoint=${new Date().toISOString()}&${formDataToURLSearchParams(
      data
    ).toString()}&${authHeader}`,
    {
      method: "POST",
      //body:data
    }
  );

  return await responseJson(res);
}

export async function fetchCSV(fileToConvert) {
  var data = new FormData();
  data.append("file", fileToConvert);

  let res = await fetch(
    `${
      api.settings.apiBase
    }/csv?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );

  if (!res.ok) return null;
  return await res.text();
}

export async function fetchCreatePolicyMeter(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${policy.scope}/permits/issuers/${
      policy.policy
    }/limits?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    //const json = await res.json();

    //return await fetchAndStorePropertyPolicies(policy.scope); // refetch
    return await fetchAndStorePolicy(policy.policy);
    //return json;
    //return created;
  }

  return await responseJson(res);
}

export async function fetchDeletePolicyMeters(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/meters?viewpoint=${new Date().toISOString()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    //return await fetchAndStorePropertyPolicies(policy.scope); // refetch
    return await fetchAndStorePolicy(policy.policy);
  }

  return await responseJson(res);
}

async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

export function resolvePermits(values, items) {
  // values is the list of permits, items is the overall state

  if (!values || !items) return null;
  values = map(
    values,
    (value, key) => items[key] || item[value] || value || key
  );
  if (!values.every((item) => !!item && !!item.id)) return null; // not done loading

  return values
    .filter((permit) => permit && permit.amenity !== "parking")
    .map((permit) =>
      !permit
        ? permit
        : merge(permit, {
            property: resolveProperty(
              items[permit.location] || permit.location,
              items
            ),
            address: items[permit.address] || permit.address,
            policy:
              items[permit.issued.policy] ||
              items[permit.issued.issuer] ||
              permit.issued.issuer,
            vehicle: items[permit.vehicle] || permit.vehicle,
            spaces: (permit.spaces || []).map((i) => items[i] || i),
            tenant: items[permit.tenant] || permit.tenant,
            attachments: Object.entries(
              get(items, ["attachments", "items", permit.id], {})
            ).map(([id, item]) => items[id] || item),
            contact: get(
              items,
              ["contacts", "items", permit.id],
              permit.contact
            ),
            //notes: get(items, ["notes", "items", permit.id], permit.notes),
          })
    );
}

export function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

export function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  return resolveAddress(item, items);
}

function processPolicyStatistics(json) {
  if (!json) return json;

  if (!json.statistics || !json.statistics.items) return json;

  if (!json.items) json.items = {};

  Object.entries(json.statistics.items).forEach(
    ([key, value]) => (json.items[`${key}:statistics`] = value)
  );

  return json;
}
